export default ({
  errorContainer: {
    display: 'flex',
    backgroundColor: '#fff',
    height: '620px',
    minHeight: '550px',
    flexDirection: 'column',
    marginBottom: '22px',
    width: '1330px',
    flex: 1,
  },
  errorSection: {
    margin: 'auto',
    width: '328px',
    height: '168px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    color: '#ffa522',
    fontSize: '16px',
    fontFamily: 'EEWBold',
    marginBottom: '25px',
  },
  errorText: {
    color: '#6e6e69',
    fontSize: '12px',
    fontFamily: 'EEWMedium',
    marginBottom: '42px',
    width: '328px',
    textAlign: 'center',
  },
  noDataFoundTxt: {
    width: '380px',
    fontSize: '16px',
  },
  updateButton: {
    width: '145px',
    border: 'none',
    color: '#fff',
    background: 'linear-gradient(-360deg, #ffa522,#ffd006)',
    borderRadius: '2px',
    fontSize: '16px',
    height: '42px',
    padding: '12.5px 29px',
    fontFamily: 'EEWBold',
    '&:hover, &:focus': {
      outline: 'none',
    }
  },
  '@media screen and (max-width: 320px)': {
    errorContainer: {
      width: '320px',
      height: '420px',
      minHeight: '450px',
    },
    errorSection: {
      width: '260px',
      height: '130px',
      margin: 'auto 30px'
    },
    errorText: {
      width: '210px',
    },
    noDataFoundTxt: {
      width: '210px',
      fontSize: '16px',
    },
  },
});