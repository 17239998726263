import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
// import { fetchChartData } from '../../redux/actions';
// import { getUser } from '../../redux/selectors';
import styles from './Imprint.styles';
import * as constants from '../../utils/constants';

const useStyles = createUseStyles(styles);
const Imprint = ({path}) => {
  const classes = useStyles();
  let history = useHistory();
  const { pathname } = useLocation();

  const onBackClickHandler = () => {
    history.goBack();
  }

  return (
    <section className={classnames(classes.imprintContainer)}>
      <label className={classnames(classes.heading)}>Impressum</label>
      <h3>Herausgeber</h3>
      <p>
      EEW Energy from Waste GmbH<br /> 
      Schöninger Straße 2 - 3<br /> 
      38350 Helmstedt<br /> 
      T +49 53 51&nbsp; 18-0<br /> 
      F +49 53 51&nbsp; 18-25 22
      </p>
      <h3>Verantwortlich für die Konzeption und den Inhalt der Website</h3>
      <p>
      EEW Energie aus der Waste GmbH <br />K
      ommunikationsabteilung <br />P
      eter Werz <br />E
      -Mail: <a href="mailto:info@eew-energyfromwaste.com" className={classnames(classes.morelink)}>info@eew-energyfromwaste.com&nbsp; </a>
      </p>
      <h3>Design, Programmierung und Support</h3>
      <p>
      <strong>iconmobile GmbH</strong><br /> 
      Wallstraße 14A<br /> 
      10179 Berlin<br /> 
      Germany
      </p>
      <p>
      T +49 30 886633 100 <br />
      F +49 30 886633 150 <br />
      <a href="mailto:info@iconmobile.com" className={classnames(classes.morelink)}>info@iconmobile.com </a><br />
      <a href="https://iconmobile.com/" target="_blank" className={classnames(classes.morelink)}>www.iconmobile.com</a>
      </p>
      <h3>Haftungsausschluss</h3>
      <p>
      Es wird keine Verantwortung dafür übernommen, dass die Daten korrekt, vollständig oder aktuell sind. Es wird keine Haftung übernommen, insbesondere nicht für Schäden oder Folgen, die sich aus der Nutzung der Inhalte ergeben. Die EEW Energy from Waste GmbH ist nicht verantwortlich für den Inhalt direkt oder indirekt verlinkter Websites. Die zivil- und strafrechtliche Haftung wird vom jeweiligen Anbieter übernommen.
      </p>
      <h3>Urheberrechte </h3>
      <p>
      EEW Energy from Waste GmbH 2013
      </p>
      <h3>Persönliche Daten</h3>
      <p>
      siehe <a href={pathname.startsWith(constants.INTERNAL_PATH) ? constants.INTERNAL_DATA_PROTECTION_PATH : constants.EXTERNAL_DATA_PROTECTION_PATH} className={classnames(classes.morelink)}>Datenschutz</a>
      </p>
      <h3>Statistische Daten</h3>
      <p>
      siehe <a href={pathname.startsWith(constants.INTERNAL_PATH) ? constants.INTERNAL_DATA_PROTECTION_PATH : constants.EXTERNAL_DATA_PROTECTION_PATH} className={classnames(classes.morelink)}>Datenschutz</a>
      </p>
      <h3>Vorsitzender des Aufsichtsrats</h3>
      <p>
      Jürgen Rauen
      </p>
      <h3>Geschäftsführung</h3>
      <p>
      Bernard M. Kemper (Vorsitzender), Markus Hauck, Dr. Joachim Manns
      </p>
      <h3>Handelsregister und CR-Nr</h3>
      <p>
      HRB 204173 District Court (Amtsgericht) Braunschweig
      </p>
      <h3>Umsatzsteuer-Identifikationsnummer</h3>
      <p>
      Umsatzsteuer-Identifikationsnummer DE 811 136 532
      </p>
      <h3>Verantwortliche Aufsichtsbehörde</h3>
      <p>
      Commercial regulatory authority (Gewerbeaufsichtsamt) Braunschweig
      </p>
      <span className={classnames(classes.backLinkBorder)}>
        <a href='javascript:void(0);' onClick={onBackClickHandler} className={classnames(classes.backLink)}>Zurück</a>
      </span>
    </section>
  );
};

export default Imprint;