import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
// import { fetchChartData } from '../../redux/actions';
// import { getUser } from '../../redux/selectors';
import styles from './DataProtection.styles';

const useStyles = createUseStyles(styles);
const DataProtection = ({path}) => {
  const classes = useStyles();
  let history = useHistory();

  const onBackClickHandler = () => {
    history.goBack();
  }

  return (
    <section className={classnames(classes.dataProtectionContainer)}>
      <label className={classnames(classes.heading)}>Datenschutz</label>
      <p>
        Die EEW Energy from Waste GmbH (EEW) und ihre Tochtergesellschaften legen großen Wert auf den Schutz personenbezogener Daten.
      </p>
      <p>
        Wir behandeln Ihre personenbezogenen Daten vertraulich und selbstverständlich gemäß den gesetzlichen Bestimmungen zum Datenschutz und wie in dieser Datenschutzerklärung beschrieben.
      </p>
      <p>
        Die Nutzung unserer Website ist grundsätzlich ohne Eingabe persönlicher Daten möglich. Wenn auf unseren Seiten personenbezogene Daten (wie Name, Postanschrift oder E-Mail-Adresse) erhoben werden, werden diese immer freiwillig zur Verfügung gestellt. Diese Daten werden ohne Ihre ausdrückliche Genehmigung nicht an Dritte weitergegeben.
      </p>
      <h3>Verantwortlichkeiten und Kontakt</h3>
      <p>
        Diese Angaben zum Datenschutz gelten für die Datenverarbeitung durch:
      </p>
      <p>
        EEW Energy from Waste GmbH<br />
        Schöninger Straße 2 - 3<br />
        38350 Helmstedt<br />
        T 0 53 51&nbsp; 18-0
      </p>
      <p>
        Die Kontaktdaten des Datenschutzbeauftragten der EEW Energy von Waste GmbH lauten wie folgt:
      </p>
      <p>
        EEW Energy from Waste GmbH<br />
        Datenschutzbeauftragter [Data Protection Officer]<br />
        Schöninger Straße 2 - 3<br />
        38350 Helmstedt
      </p>
      <p>
        E-Mail:&nbsp; <a href="mailto:extdsb@vzm.de">extdsb@vzm.de</a>
      </p>
      <h3>Erhebung und Verarbeitung personenbezogener Daten</h3>
      <p>
        Personenbezogene Daten sind alle Informationen, die sich auf Ihre Identität beziehen, wie z. B. Ihr Name, Ihre E-Mail-Adresse oder Ihre Postanschrift. Diese Daten werden nur gespeichert, wenn Sie diese von der Waste GmbH an EEW Energy senden, z. B. wenn Sie Informationsmaterial bestellen, ein Kontaktformular ausfüllen oder eine Online-Bewerbung einreichen.
      </p>
      <h3>Verwendung und Weitergabe personenbezogener Daten</h3>
      <p>
        EEW verwendet Ihre persönlichen Daten im Allgemeinen, um Ihre Anfrage zu beantworten, Ihre Bestellung zu bearbeiten oder Ihnen Zugriff auf bestimmte Informationen oder Angebote zu gewähren. Die EEW Energy from Waste GmbH wird Ihre personenbezogenen Daten weder an Dritte verkaufen noch anderweitig vermarkten.
      </p>
      <h3>Deine Entscheidung</h3>
      <p>
        Sie entscheiden frei, welche Informationen Sie von der Waste GmbH an EEW Energy übermitteln. Wenn Sie keine Informationen übermitteln möchten, können Sie möglicherweise bestimmte Dienste wie Kontaktformulare oder ähnliches nicht nutzen.
      </p>
      <h3>Informationen werden automatisch gesammelt</h3>
      <p>
        Wenn Sie von der Waste GmbH auf die Website von EEW Energy zugreifen, werden allgemeine Informationen automatisch erfasst (dh nicht über die Registrierung), die in nicht personalisierter Form verwendet werden. Die verwendeten Webserver speichern standardmäßig
      </p>
      <ul>
        <li>Den Namen Ihres Internetdienstanbieters (bei Firmennetzwerken den Namen Ihres Unternehmens),</li>
        <li>Die Website, die Sie zuletzt besucht haben,</li>
        <li>Die Websites, die Sie über Websites von EEW Energy von Waste GmbH besucht haben,</li>
        <li>Die Namen der Dateien, auf die zugegriffen wird,</li>
        <li>Datum und Uhrzeit des Zugriffs</li>
        <li>Und möglicherweise das Betriebssystem und die Browserversion Ihres Computers</li>
      </ul>
      <p>
        This information is evaluated in an anonymised form. It is used exclusively to improve the appeal, content and functionality of the website. Personalised user profiles are not created. There is no further use or disclosure of this data.
      </p>
      <h3>Personal data in the contact form</h3>
      <p>
        If you wish to contact us via the SSL-secured form integrated into the website <a href="https://www.eew-energyfromwaste.com/de/service/infomaterial.html" target="_blank">www.eew-energyfromwaste.com/de/service/infomaterial.html</a>, we need to collect your data, such as your name, e-mail address, postal address and telephone number. Your personal data will be used only to process your enquiry, i.e. exclusively for the purpose for which you provided the information.
      </p>
      <p>
        This submission of your personal details is done on an expressly voluntary basis and you agree that we may also contact you via this communication data to deal with your enquiry.
      </p>
      <p>
        If you request information using the contact form or send us an e-mail, this data is stored by us until we have dealt with your enquiry or until expiry of any statutory retention duties. We will ensure that the data is stored so that it is secure against unauthorised access by third parties.
      </p>
      <h3>Information for applicants</h3>
      <p>
        EEW takes data protection very seriously. In our data processing we adhere strictly to the statutory provisions, in particular the European General Data Protection Regulation (GDPR) and the German Data Protection Act (BDSG).
      </p>
      <p>
        Responsibility for this data processing lies with EEW Energy from Waste GmbH, Schöninger Straße 2 – 3, 38350 Helmstedt.
      </p>
      <p>
        The above-mentioned company assumes the function of a central personnel department for the companies of EEW. If the job for which you have applied is advertised by a company other than EEW Energy from Waste GmbH, then EEW Energy from Waste GmbH assumes the function of the personnel department on the legal basis of Article 6&nbsp;(1)&nbsp;(f) GDPR. An overview of our corporate structure can be found at <a href="https://www.eew-energyfromwaste.com/de/unternehmen/struktur.html" target="_blank">https://www.eew-energyfromwaste.com/de/unternehmen/struktur.html</a>
      </p>
      <p>
        You can contact the Data Protection Officer of EEW Energy from Waste GmbH at EEW Energy from Waste GmbH, Data Protection Officer, Schöninger Straße 2 – 3 ,38350 Helmstedt, datenschutz@eew-energyfromwaste.com.
      </p>
      <p>
        <strong>Aim and legal basis of data processing. </strong>
      </p>
      <p>
        The processing of your data serves to process your application. The processing of your data is necessary to carry out pre-contractual measures resulting from your enquiry. &nbsp;If your application is successful, the processing also serves to perform a contract to which you are a party as an involved person. In both cases the processing is carried out on the basis of Article 6&nbsp;(1)&nbsp;(b) GDPR.
      </p>
      <p>
        <strong>Data storage</strong>
      </p>
      <p>
        Your personal data will be stored for the duration of the application process and beyond that for 6 months and will then be deleted.
      </p>
      <p>
        Deviating from this, our confirmation of receipt of your application will be stored as business correspondence for six years and then deleted. Only the data contained in this receipt of confirmation, in particular your name, the contact details you gave, the date of your application and the name of the job for which you applied are subject to this storage. This extended period of storage serves compliance with legal obligations and is based upon Article 6&nbsp;(1)&nbsp;(c) GDPR and Section 257 of the German Commercial Code (HGB).
      </p>
      <p>
        If your personal data is required beyond this (for instance in connection with the assertion or defence of civil law claims in connection with your application) it will be deleted as soon as continued storage of the data is no longer necessary for this purpose. In this case too the storage is based upon Article 6&nbsp;(1)&nbsp;(f) GDPR.
      </p>
      <p>
        <strong>Rights of the data subject</strong>
      </p>
      <p>
        You have the right<br />
        to be informed of the data stored by the controller, i.e. EEW, and the purpose of the processing (Article 15 GDPR),
      </p>
      <ul>
        <li>to correction of inaccurate or completion of incomplete data (Article 16 GDPR),</li>
        <li>to deletion of any illegally processed or no longer required data (Article 17 GDPR),</li>
        <li>to restrict the processing (Article 18 GDPR)</li>
        <li>to object to the processing, in particular if this is done to protect the justified interests of the controller (Article 21 GDPR)</li>
        <li>to transmission of data if the processing is based upon consent or the performance of a contract or is done using automated processes (Article 20 GDPR).</li>
      </ul>
      <p>
        To assert your legal rights you can contact us in the way most convenient for you, for instance through the Data Protection Officer of EEW.
      </p>
      <p>
        <strong>Possibility to lodge a complaint</strong>
      </p>
      <p>
        You also have the option of lodging a complaint with our Data Protection Officer using the contact details given above or you may also contact the responsible data protection supervisory authority.&nbsp;
      </p>
      <p>
        We wish you success with your application to EEW.
      </p>
      <h3>Cookies</h3>
      <p>
        EEW itself uses session cookies to offer users greater convenience. This means that visitors to the EEW websites can, for instance, continue with a catalogue order they have started even after clicking another page in between, without having to enter their own data again.
      </p>
      <h3>Google Analytics</h3>
      <p>
        This website uses the functions of the web analysis service Google Analytics. The provider is Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.
      </p>
      <p>
        Google Analytics uses what are known as “cookies”, text files which are stored on your computer and enable analysis of your use of the website. The information created by the cookie about your use of this website is generally transmitted to a Google server in the USA and stored there. If IP anonymisation is active on this website your IP address is however first shortened by Google within the Member States of the European Union or other signatory states to the Agreement on the European Economic Area. Only in exceptional cases is the full IP address transmitted to a Google server in the USA and shortened there. Google uses this information on behalf of the operators of this website to evaluate your use of this website, to compile reports on the website activities and to provide other services to the website operator associated with use of the website and the Internet. The IP address of your browser transmitted to Google Analytics is not associated with any other data by Google.
      </p>
      <p>
        We wish to inform you that on this website Google Analytics has been expanded by the code “anonymizeIp” in order to ensure the anonymised collection of IP addresses (known as IP masking).
      </p>
      <p>
        You can prevent the storage of cookies by making the appropriate settings on your browser software; however we wish to inform you that in this case you may not be able to make full use of all the functions of this website. You can also prevent collection by Google of the data created by the cookie and relating to your use of the website (incl. your IP address) and Google’s processing of this data by downloading and installing the browser plugin available under the following link:&nbsp; (<a href="http://tools.google.com/dlpage/gaoptout?hl=de" title="Öffnet einen externen Link in einem neuen Fenster" target="_blank">http://tools.google.com/dlpage/gaoptout?hl=de</a>).
      </p>
      <p>
        As an alternative to the browser plugin or within browsers on mobile devices you can prevent collection of information by Google Analytics by clicking on the following link. This sets an opt-out cookie which prevents the future collection of your data when you visit this website:
      </p>
      <p>
        Deactivate Google Analytics
      </p>
      <p>
        Please remember that you have to reset this opt-out cookie if you delete this or all cookies with your browser setting. Information on the conditions of use and data protection at Google can be found at <a href="http://www.google.com/analytics/terms/de.html" title="Informationen zu Nutzungsbedingungen und Datenschutz von Google" target="_blank">http://www.google.com/analytics/terms/de.html</a> bzw. unter <a href="https://www.google.de/intl/de/policies/" title="Informationen zu Nutzungsbedingungen und Datenschutz von Google" target="_blank">https://www.google.de/intl/de/policies/</a>.
      </p>
      <h3>Security</h3>
      <p>
        EEW Energy from Waste GmbH takes precautions to protect your personal data from loss, damage, falsification, manipulation and unauthorised access. The statutory data protection provisions are of course implemented.
      </p>
      <h3>Children</h3>
      <p>
        EEW Energy from Waste GmbH advises all parents and guardians to instruct their children in the safe and responsible handing of personal data on the Internet. Children should not send personal data to the EEW website without permission from parents or guardians. EEW Energy from Waste GmbH offers an assurance that it will not knowingly collect personal data from children, nor in any way use this or disclose it to third parties.
      </p>
      <h3>Links to other websites</h3>
      <p>
        This declaration on data protection applies to the presentation of EEW Energy from Waste GmbH on <a href="http://www.eew/" target="_blank">www.eew</a>-energyfromwaste.com. The websites in this Internet presentation may contain links to other providers within and outside the EEW group to which this privacy statement does not apply. If you leave the EEW website it is recommended that you read carefully the data protection guideline of every website which collects personal data.
      </p>
      <h3>Right to information</h3>
      <p>
        If you have any questions regarding the processing of your personal data, please contact:
      </p>
      <p>
        EEW Energy from Waste GmbH<br />
        Datenschutzbeauftragter [Data Protection Officer]<br />
        Schöninger Straße 2 - 3<br />
        38350 Helmstedt<br />
        Email: <a href="http://www-neu.eew-energyfromwaste.com/" target="_blank">datenschutz@eew-energyfromwaste.com</a>
      </p>
      <p>
        Upon request you will be informed in writing in line with valid legislation as to whether and which personal data EEW Energy from Waste GmbH has stored about you through its websites.
      </p>
      <h3>Your rights</h3>
      <p>
        Upon request and authentication we will be happy to inform you in writing in line with the valid legislation as to whether personal data relating to you is being processed. If this is the case you have the right to information on this personal data and to the specific information given in Article 15 GDPR.
      </p>
      <p>
        If the personal data relating to you is incorrect, you have the right to demand immediate rectification and, if applicable, completion of incomplete personal data (Article 16 GDPR).
      </p>
      <p>
        You have the right to demand that personal data relating to you is immediately deleted if one of the specific grounds set out in Article 17 GDPR applies, for instance if the data is no longer needed for the intended purpose (right to deletion).
      </p>
      <p>
        You also have the right to demand a restriction of the processing if one of the conditions listed in Article 18 GDPR applies; for instance if you have lodged an objection to the processing, for the duration of the examination of the case by the person responsible.
      </p>
      <p>
        You have the right, for reasons relating to your particular situation, to lodge an objection at any time to the processing of personal data relating to you. Our company will then no longer process the personal data, unless we can demonstrate compelling grounds for the processing or if the processing serves the establishment, exercise or defence of legal claims (Article 21 GDPR).
      </p>
      <p>
        Without prejudice to any other administrative or judicial remedy, you have the right to lodge a complaint with a supervisory authority if you are of the opinion that the processing of personal data relating to you infringes the GDPR (Article 77 GDPR). You can exercise this right at a supervisory authority in the Member State of your habitual residence, your place of employment or the place of the alleged infringement. In Lower Saxony the person responsible is:
      </p>
      <p>
        Die Landesbeauftragte für den Datenschutz Niedersachsen [State Data Protection Officer for Lower Saxony]
      </p>
      <p>
        Barbara Thiel
      </p>
      <p>
        Prinzenstraße 5
      </p>
      <p>
        30159 Hannover
      </p>
      <h3>Notification of changes</h3>
      <p>
        If this privacy statement should be changed, notification of the change in this privacy statement will be posted on the homepage and other suitable places.
      </p>
      <span className={classnames(classes.backLinkBorder)}>
        <a href='javascript:void(0);' onClick={onBackClickHandler} className={classnames(classes.backLink)}>Zurück</a>
      </span>
    </section>
  );
};

export default DataProtection;