import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../../src/react-dates-overrride.css';
import '../../../src/month-picker.css';

import Picker from 'react-month-picker'
import React, { useState, useEffect, isValidElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import styles from './DatePicker.styles';
import moment from 'moment';
import classnames from "classnames";
import * as constants from '../../utils/constants';

const useStyles = createUseStyles(styles);

const MonthPicker = ({ dateChangeHandler }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentDate = moment();
  const [ singleValue, setSingleValue ] = useState({month: currentDate.month()+1, year: currentDate.year()});
  const [ showShotcutPanel, setShowShotcutPanel ] = useState(false);
  const pickAMonth = React.createRef();
  const pickerLang = {
    months: ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
    from: 'From', to: 'To',
  }
  let yearMap = [];
  for(let i =  currentDate.year() - constants.YEAR_COUNT; i <= currentDate.year(); i++) {
    yearMap.push(i);
  }
  const [ yearsOptions, setYearOptions ] = useState({min: {year: currentDate.year() - constants.YEAR_COUNT, month: 1}, max: {year: currentDate.year(), month: currentDate.month()+1}});

  const makeText = m => {
    return (pickerLang.months[m.month-1] + ', ' + m.year)
  }

  const handleMonthChange = (year, month) => {
    setSingleValue({year, month});
    dateChangeHandler({year, month});
    setShowShotcutPanel(false);
    pickAMonth.current.dismiss();
  }

  const handleMonthDissmis = () => {
    setShowShotcutPanel(false);
  }

  const handleClickMonthBox = (e) => {
    pickAMonth.current.show();
    setShowShotcutPanel(true);
  }

  const setCurrentMonth = () => {
    const value = { year: moment().year(), month: moment().month()+1 };
    const yearIndexes = [yearMap.indexOf(value.year)];
    pickAMonth.current.state.rawValue = {...pickAMonth.current.state.rawValue, ...value};
    pickAMonth.current.state.yearIndexes = yearIndexes;
    handleMonthChange(value.year, value.month, pickAMonth);
  }

  return (
    <Picker
        ref={pickAMonth}
        years={yearsOptions}
        value={singleValue}
        lang={pickerLang.months}
        onChange={handleMonthChange}
        onDismiss={handleMonthDissmis}
      >
      <div className="box" onClick={handleClickMonthBox}>
        <div className="content"><label>{makeText(singleValue)}</label></div>
        <button className="SingleDatePickerInput_calendarIcon calender-icon" type="button"><svg className="SingleDatePickerInput_calendarIcon_svg SingleDatePickerInput_calendarIcon_svg_1" focusable="false" viewBox="0 0 1393.1 1500"><path d="m107 1393h241v-241h-241zm295 0h268v-241h-268zm-295-295h241v-268h-241zm295 0h268v-268h-268zm-295-321h241v-241h-241zm616 616h268v-241h-268zm-321-616h268v-241h-268zm643 616h241v-241h-241zm-322-295h268v-268h-268zm-294-723v-241c0-7-3-14-8-19-6-5-12-8-19-8h-54c-7 0-13 3-19 8-5 5-8 12-8 19v241c0 7 3 14 8 19 6 5 12 8 19 8h54c7 0 13-3 19-8 5-5 8-12 8-19zm616 723h241v-268h-241zm-322-321h268v-241h-268zm322 0h241v-241h-241zm27-402v-241c0-7-3-14-8-19-6-5-12-8-19-8h-54c-7 0-13 3-19 8-5 5-8 12-8 19v241c0 7 3 14 8 19 6 5 12 8 19 8h54c7 0 13-3 19-8 5-5 8-12 8-19zm321-54v1072c0 29-11 54-32 75s-46 32-75 32h-1179c-29 0-54-11-75-32s-32-46-32-75v-1072c0-29 11-54 32-75s46-32 75-32h107v-80c0-37 13-68 40-95s57-39 94-39h54c37 0 68 13 95 39 26 26 39 58 39 95v80h321v-80c0-37 13-69 40-95 26-26 57-39 94-39h54c37 0 68 13 94 39s40 58 40 95v80h107c29 0 54 11 75 32s32 46 32 75z"></path></svg></button>
      </div>
      {showShotcutPanel ?
      <svg role="presentation" focusable="false" className="DateInput_fang DateInput_fang_1"><path className="DateInput_fangShape DateInput_fangShape_1" d="M0,10 20,10 10,0z"></path><path className="DateInput_fangStroke DateInput_fangStroke_1" d="M0,10 10,0 20,10"></path></svg>
      :null}
      {showShotcutPanel ?
      <div className={classnames(classes.shortcutButtonPanel, classes.actualMonth)} 
            onClick= {setCurrentMonth}>
        <span><a href='#' onClick={(e) => e.preventDefault()}>Aktueller Monat</a></span>
      </div>
      :null}
    </Picker>
  );
  return null
};

export default MonthPicker;