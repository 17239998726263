export default ({
  trackingChart: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#fff',
    '& svg': {
      width: '100%',
      overflow: 'visible',
      flex: 1,
    },
  },
  heading: {
    fontFamily: 'EEWBold',
    fontSize: '20px',
    color: '#000',
  },
  date: {
    fontFamily: 'EEWRegular',
    fontSize: '12px',
    color: '#000',
  },
  chartHeading: {
    width: '100%',
    height: '39px',
    padding: '23px 0px 0px 14px',
    marginBottom: '53px',
  },
  legendsContainer: {
    display: 'flex',
    marginLeft: '2.5rem',
    marginBottom: '40px',
    '& label': {
      fontFamily: 'EEWMedium',
      marginLeft: '10px',
      marginRight: '30px',
      lineHeight: '2rem',
      fontSize: '12px',
    },
  },
  colorBoxNormal: {
    height: 30,
    width: 30,
    backgroundColor: '#bbefe5',
  },
  colorBoxCurrent: {
    height: 30,
    width: 30,
    background: 'linear-gradient(-360deg, #ffa522,#ffd006)',
  },
  colorBoxHighlighted: {
    height: 30,
    width: 30,
    backgroundColor: '#65beaa',
  },
  colorBoxPredictions: {
    height: 30,
    width: 30,
    backgroundColor: 'royalblue',
  },
  desktopVisible: {
    display: 'block',
  },
  mobileVisible: {
    display: 'none',
  },
  '@media screen and (max-width: 320px)': {
    trackingChart: {
      
    },
    legendsContainer: {
      marginLeft: '37px',
      marginBottom: '41px',
      '& label': {
        marginLeft: '5px',
        marginRight: '50px',
        lineHeight: '14px',
        fontSize: '10px',
      },
    },
    heading: {
      fontSize: '16px',
    },
    chartHeading: {
      width: '296px',
      height: '56px',
      padding: '21px 0px 0px 24px',
      marginBottom: '40px',
    },
    desktopVisible: {
      display: 'none',
    },
    mobileVisible: {
      display: 'block',
    },
    colorBoxNormal: {
      height: 14,
      width: 14,
    },
    colorBoxCurrent: {
      height: 14,
      width: 14,
    },
    colorBoxHighlighted: {
      height: 14,
      width: 14,
    },
  },
});
