import React from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from '../Header';
import Footer from '../Footer';
import Imprint from '../Imprint';
import DataProtection from '../DataProtection';
import Login from '../Login';
import Dashboard from '../Dashboard';
import * as utils from '../../utils/utils';
import * as constants from '../../utils/constants';

const App = () => {
  const isAuthenticated = utils.getSessionObjKey('isAuthenticated');
  
  return (
    <div className='mainContainer'>
      <div className='backgroundContainer'></div>
      <Router>
        <Header isAuthenticated={isAuthenticated} />
        <Switch>
          <Route path={constants.INTERNAL_IMPRINT_PATH} render={props => <Imprint />} />
          <Route path={constants.INTERNAL_DATA_PROTECTION_PATH} render={props => <DataProtection />} />
          <Route path={constants.EXTERNAL_IMPRINT_PATH} render={props => <Imprint />} />
          <Route path={constants.EXTERNAL_DATA_PROTECTION_PATH} render={props => <DataProtection />} />

          {isAuthenticated === 'valid' ?
          <Route path={constants.INTERNAL_PATH} render={props => <Dashboard {...props} />} />
          :<Route path={constants.INTERNAL_PATH} render={props => <Login {...props} isAuthenticated={isAuthenticated} />} />}
          
          <Route path={constants.EXTERNAL_PATH} render={props => <Dashboard {...props} />} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
