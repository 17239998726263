import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import {
  fetchExternalChartData,
  fetchInternalChartData,
  updatePredictionChartData,
} from "../../redux/actions";
import styles from "./Dashboard.styles";
import { DayChart, WeekChart, MonthChart, YearChart } from "../ChartUI";
import Loader from "../custom/Loader";
import ErrorHandler from "../custom/ErrorHandler";
import moment from "moment";
import { DatePicker, WeekPicker, MonthPicker, YearPicker } from "../DatePicker";
import CustomSelect from "../custom/CustomSelect";
import * as constants from "../../utils/constants";
import { getLoader, getError } from "../../redux/selectors";

const useStyles = createUseStyles(styles);

const defaultConfig = {
  selectedView: "Tag",
  datePickerLabel: "Datum",
  viewOptions: ["Tag", "Woche", "Monat", "Jahr"],
  selectedDate: moment().format("DD.MM.YYYY"),
  selectedWeek: {
    startDate: moment().startOf("isoWeek").format("DD.MM.YYYY"),
    endDate: moment().endOf("isoWeek").format("DD.MM.YYYY"),
  },
  selectedMonth: { month: moment().month() + 1, year: moment().format("YYYY") },
  selectedYear: moment().format("YYYY"),
  selectedFilterType: "Gesamtdauer",
  filterTypeOptions: ["Wartezeit", "Entleerung", "Gesamtdauer"],
  externalDayDataRequest: {
    from: moment().hour("05").minutes("30").format("YYYY-MM-DD[T]HH:mm:ss[Z]"),
    to: moment().hour("19").minutes("59").format("YYYY-MM-DD[T]HH:mm:ss[Z]"),
    location_id: 1,
  },
};

const Dashboard = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const loader = useSelector(getLoader);
  const error = useSelector(getError);
  const [selectedView, setSelectedView] = useState(defaultConfig.selectedView);
  const [datePickerLabel, setDatePickerLabel] = useState(
    defaultConfig.datePickerLabel
  );
  const [selectedDate, setSelectedDate] = useState(defaultConfig.selectedDate);
  const [selectedWeek, setSelectedWeek] = useState(defaultConfig.selectedWeek);
  const [selectedMonth, setSelectedMonth] = useState(
    defaultConfig.selectedMonth
  );
  const [selectedYear, setSelectedYear] = useState(defaultConfig.selectedYear);
  const [selectedFilterType, setSelectedFilterType] = useState(
    defaultConfig.selectedFilterType
  );
  const [disablePrev, setDisablePrev] = useState(true);
  const [disableNext, setDisableNext] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (pathname.startsWith(constants.INTERNAL_PATH)) {
        loadInternalChartData(selectedView, selectedDate);
      } else {
        dispatch(
          fetchExternalChartData(
            defaultConfig.externalDayDataRequest,
            selectedView
          )
        );
      }
    };
    fetchData();
  }, []);

  const viewChangeHandler = (value) => {
    const view = value;
    setSelectedView(view);
    setDatePickerLabel(view === "Tag" ? "Datum" : view);
    setSelectedFilterType(defaultConfig.selectedFilterType);
    loadChart(view);
  };

  const loadChart = (view) => {
    let dateVal;
    if (view === "Tag") {
      dateVal = selectedDate;
    } else if (view === "Woche") {
      dateVal = selectedWeek.endDate;
    } else if (view === "Monat") {
      dateVal = selectedMonth;
    } else {
      //Jahr
      dateVal = selectedYear;
    }
    loadInternalChartData(view, dateVal);
  };

  const dateChangeHandler = (val) => {
    let requestObj;
    if (selectedView === "Tag") {
      setSelectedDate(moment(val).format("DD.MM.YYYY"));
    } else if (selectedView === "Woche") {
      setSelectedWeek({
        startDate: moment(val, "DD.MM.YYYY")
          .startOf("isoWeek")
          .format("DD.MM.YYYY"),
        endDate: moment(val, "DD.MM.YYYY")
          .endOf("isoWeek")
          .format("DD.MM.YYYY"),
      });
    } else if (selectedView === "Monat") {
      setSelectedMonth({ ...val });
    } else {
      //Jahr
      setSelectedYear(val);
    }
    loadInternalChartData(selectedView, val);
  };

  const loadInternalChartData = async (view, val) => {
    let requestObj;
    if (view === "Tag") {
      requestObj = {
        location_id: 1,
        unit: "hour",
        to: moment(val, "DD.MM.YYYY")
          .hour("19")
          .minutes("59")
          .format("YYYY-MM-DD[T]HH:mm:ss[Z]"), //"2020-06-19T23:59:59Z",
        from: moment(val, "DD.MM.YYYY")
          .hour("05")
          .minutes("30")
          .format("YYYY-MM-DD[T]HH:mm:ss[Z]"), //"2020-06-19T00:00:00Z"
        unit: "30mins",
      };
    } else if (view === "Woche") {
      requestObj = {
        location_id: 1,
        unit: "day",
        to: moment(val, "DD.MM.YYYY")
          .endOf("isoWeek")
          .endOf("day")
          .format("YYYY-MM-DD[T]HH:mm:ss[Z]"), //"2020-06-19T23:59:59Z",
        from: moment(val, "DD.MM.YYYY")
          .startOf("isoWeek")
          .startOf("day")
          .format("YYYY-MM-DD[T]HH:mm:ss[Z]"), //"2020-06-19T00:00:00Z"
      };
    } else if (view === "Monat") {
      requestObj = {
        location_id: 1,
        unit: "day",
        to: moment()
          .month(val.month - 1)
          .year(val.year)
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD[T]HH:mm:ss[Z]"), //"2020-08-31T23:59:59Z",
        from: moment()
          .month(val.month - 1)
          .year(val.year)
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD[T]HH:mm:ss[Z]"), //"2020-08-01T00:00:00Z"
      };
    } else {
      //Jahr
      requestObj = {
        location_id: 1,
        unit: "month",
        to: moment()
          .year(val)
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD[T]HH:mm:ss[Z]"), //"2019-12-31T23:59:59Z",
        from: moment()
          .year(val)
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD[T]HH:mm:ss[Z]"), //"2019-01-01T00:00:00Z"
      };
    }
    if (view === "Tag") {
      const request = defaultConfig.externalDayDataRequest;
      request.from = moment(val, "DD.MM.YYYY")
        .hour("05")
        .minutes("30")
        .format("YYYY-MM-DD[T]HH:mm:ss[Z]");
      request.to = moment(val, "DD.MM.YYYY")
        .hour("19")
        .minutes("59")
        .format("YYYY-MM-DD[T]HH:mm:ss[Z]");
      await dispatch(fetchExternalChartData(request, view, "show-predictions"));
    }
    await dispatch(fetchInternalChartData(requestObj, view));
  };

  const onFilterTypeChange = (e) => {
    setSelectedFilterType(e.target.value);
  };

  const getFilterTypeOptions = () => {
    return defaultConfig.filterTypeOptions.map((filterType, key) => (
      <label className="filterType" key={`filterType-${key}`}>
        <input
          type="radio"
          id={filterType}
          name="filterType"
          value={filterType}
          onChange={onFilterTypeChange}
          checked={selectedFilterType === filterType}
        />
        <i></i> {filterType}
      </label>
    ));
  };

  const isInActiveRange = (date) => {
    const currentDate = moment.utc(date).format("YYYY-MM-DD");
    const startDate = moment.utc().subtract(1, "d").format("YYYY-MM-DD");
    const endDate = moment.utc().add(8, "d").format("YYYY-MM-DD");
    return moment(currentDate).isBetween(startDate, endDate);
  };

  const disableNavIcons = (updatedDate, dir) => {
    dir === "next" ? setDisablePrev(false) : setDisableNext(false);
    const startDate = moment.utc().subtract(1, "d").format("YYYY-MM-DD");
    const endDate = moment.utc().add(8, "d").format("YYYY-MM-DD");
    if (
      moment(
        moment.utc(updatedDate).subtract(1, "d").format("YYYY-MM-DD")
      ).isSame(startDate)
    ) {
      setDisablePrev(true);
    }
    if (
      moment(moment.utc(updatedDate).add(1, "d").format("YYYY-MM-DD")).isSame(
        endDate
      )
    ) {
      setDisableNext(true);
    }
  };

  const isWeekend = (date) => {
    const excludedDays = ["Saturday", "Sunday"];
    const day = moment.utc(date).format("dddd");
    return excludedDays.includes(day);
  };

  const onNavIconClick = (dir, e) => {
    if (e.target.classList.value.includes("disabledArrow")) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }
    let externalChartRequest = defaultConfig.externalDayDataRequest;
    let updatedDate;
    if (dir === "prev") {
      updatedDate = moment.utc(externalChartRequest.from).subtract(1, "d");
      if (isWeekend(updatedDate)) {
        updatedDate = moment.utc(externalChartRequest.from).subtract(3, "d");
      }
    } else if (dir === "next") {
      updatedDate = moment.utc(externalChartRequest.from).add(1, "d");
      if (isWeekend(updatedDate)) {
        updatedDate = moment.utc(externalChartRequest.from).add(3, "d");
      }
    }
    if (isInActiveRange(updatedDate)) {
      externalChartRequest.from = moment
        .utc(updatedDate)
        .hour("05")
        .minutes("30")
        .format("YYYY-MM-DD[T]HH:mm:ss[Z]");
      externalChartRequest.to = moment
        .utc(updatedDate)
        .hour("19")
        .minutes("59")
        .format("YYYY-MM-DD[T]HH:mm:ss[Z]");
      disableNavIcons(updatedDate, dir);
      setSelectedDate(moment(updatedDate).format("DD.MM.YYYY"));
    } else {
      setDisablePrev(dir === "prev" ? true : false);
      setDisableNext(dir === "next" ? true : false);
      return;
    }
    dispatch(fetchExternalChartData(externalChartRequest, selectedView));
  };

  return (
    <section
      className={classnames(
        classes.dashboardContainer,
        pathname.startsWith(constants.INTERNAL_PATH) ? "" : classes.eewExternal
      )}
    >
      <div
        className={classnames(
          classes.dashboard,
          pathname.startsWith(constants.INTERNAL_PATH)
            ? ""
            : classes.externalDashboard
        )}
      >
        {pathname.startsWith(constants.INTERNAL_PATH) ? (
          <div className={classnames(classes.filterActions)}>
            <div className={classnames(classes.leftActions)}>
              <label>Zeitansicht:</label>
              <CustomSelect
                selectedValue={selectedView}
                options={defaultConfig.viewOptions}
                onChange={viewChangeHandler}
              />
              <label>{datePickerLabel}:</label>
              {selectedView === "Tag" ? (
                <DatePicker dateChangeHandler={dateChangeHandler} />
              ) : null}
              {selectedView === "Woche" ? (
                <WeekPicker dateChangeHandler={dateChangeHandler} />
              ) : null}
              {selectedView === "Monat" ? (
                <MonthPicker dateChangeHandler={dateChangeHandler} />
              ) : null}
              {selectedView === "Jahr" ? (
                <YearPicker dateChangeHandler={dateChangeHandler} />
              ) : null}
            </div>
            <div className={classnames(classes.rightActions)}>
              <label>Filter:</label>
              {getFilterTypeOptions()}
            </div>
          </div>
        ) : null}
        {!pathname.startsWith(constants.INTERNAL_PATH) ? (
          <div
            className={classnames(
              classes.prevButton,
              disablePrev ? [classes.disabledArrow, "disabled"] : ""
            )}
            onClick={(e) => onNavIconClick("prev", e)}
          ></div>
        ) : null}
        {loader && loader.show ? (
          <Loader />
        ) : error && error.show ? (
          <ErrorHandler selectedView={selectedView} loadChart={loadChart} />
        ) : (
          <div className={classnames(classes.chartContainer)}>
            {selectedView === "Tag" ? (
              <DayChart
                selectedDate={selectedDate}
                selectedFilterType={selectedFilterType}
                defaultConfig={defaultConfig}
              />
            ) : null}
            {selectedView === "Woche" ? (
              <WeekChart
                selectedWeek={selectedWeek}
                selectedFilterType={selectedFilterType}
              />
            ) : null}
            {selectedView === "Monat" ? (
              <MonthChart
                selectedMonth={selectedMonth}
                selectedFilterType={selectedFilterType}
              />
            ) : null}
            {selectedView === "Jahr" ? (
              <YearChart
                selectedYear={selectedYear}
                selectedFilterType={selectedFilterType}
              />
            ) : null}
          </div>
        )}
        {!pathname.startsWith(constants.INTERNAL_PATH) ? (
          <div
            className={classnames(
              classes.nextButton,
              disableNext ? [classes.disabledArrow, "disabled"] : ""
            )}
            onClick={(e) => onNavIconClick("next", e)}
          ></div>
        ) : null}
      </div>
      <span
        className={classnames(
          classes.impressumBorder,
          pathname.startsWith(constants.INTERNAL_PATH)
            ? ""
            : classes.imprintExternalBorder
        )}
      >
        <a
          href={
            pathname.startsWith(constants.INTERNAL_PATH)
              ? constants.INTERNAL_IMPRINT_PATH
              : constants.EXTERNAL_IMPRINT_PATH
          }
          className={classnames(classes.impressum)}
        >
          Impressum
        </a>
      </span>
    </section>
  );
};

export default Dashboard;
