import rightArrow from './../../resources/icons/arrow-orange.png';

export default ({
  loginContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#f7f8f8',
    // height: '957px',
    alignItems: 'center',
  },
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '222px',
    maxWidth: '388px',
    maxHeight: '440px',
    padding: '29px 30px 10.5px 30px',
    borderRadius: '3px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    '& input:not([type = "checkbox"])': {
      appearance: 'none',
      border: 'none',
      borderBottom: '1px solid #d1d5d5',
      width: '324px',
      height: '28px',
      caretColor: '#bbefe5',
    },
    '& input[type = "password"]': {
      color: '#757575 !important',
      letterSpacing: '3px',
    },
    '& input:not([type = "checkbox"]):focus, & input:not([type = "checkbox"]):hover': {
      appearance: 'none',
      border: 'none',
      borderBottom: '1px solid #ffa522',
      outline: 'none',
    },
    '& input:not([type = "checkbox"]):-webkit-autofill, & input:not([type = "checkbox"]):-webkit-autofill:hover,  & input:not([type = "checkbox"]):-webkit-autofill:focus, & input:not([type = "checkbox"]):-webkit-autofill:active': {
      boxShadow: '0 0 0 30px white inset !important',
      color: '#757575 !important',
    },
    '& input[type = "password"]:-webkit-autofill, & input[type = "password"]:-webkit-autofill:hover,  & input[type = "password"]:-webkit-autofill:focus, & input[type = "password"]:-webkit-autofill:active': {
      textFillColor: '#757575 !important',
    }
  },
  savePassword: {
    marginTop: '15px',
    fontSize: '12px',
    fontFamily: 'EEWMedium',
    '& i': {
      marginRight: '10px',
      display: 'inline-block',
      verticalAlign: 'middle',
      width:  '16px',
      height: '16px',
      borderRadius: '10%',
      transition: '0.2s',
      boxShadow: 'inset 0 0 0 8px #fff',
      border: '1px solid #e5e6e6',
      background: '#fff',
    },
    '&:hover &i': {
      boxShadow: 'inset 0 0 0 3px #fff',
      background: '#ffa522',
    },
    '& input[type = "checkbox"]': {
      position: 'absolute',
      opacity: 0,
      width: 0,
      height: 0,
      cursur: 'pointer',
    },
    '& input[type = "checkbox"]:checked': {
      '& i': {
        boxShadow: 'inset 0 0 0 8px #ffa522',
        background: '#ffa522',
      }
    },
  },
  formTitle: {
    color: '#ffa522',
    fontFamily: 'EEWBold',
    fontSize: '16px',
  },
  invalidDataLabel: {
    color: '#9f0f00',
    fontFamily: 'EEWMedium',
    fontSize: '12px',
    marginTop: '25px',
    marginBottom: '28px',
  },
  errorLabel: {
    fontSize: '12px',
    fontFamily: 'EEWMedium',
    color: '#9f0f00',
    marginTop: '5px',
  },
  visible: {
    visibility: 'visible',
  },
  hidden: {
    visibility: 'hidden',
    marginTop: '39px',
  },
  inputError: {
    borderBottom: '1px solid #9f0f00 !important',
  },
  inputLabel: {
    display: 'flex',
    fontSize: '12px',
    fontFamily: 'EEWBold',
  },
  email: {
    // marginTop: '10px',
  },
  password: {
    marginTop: '39.6px',
  },
  infoLabel: {
    display: 'flex',
    fontSize: '12px',
    fontFamily: 'EEWMedium',
    marginTop: '32px',
  },
  loginButton: {
    width: '125px',
    border: 'none',
    color: '#fff',
    background: 'linear-gradient(to bottom, #ffd006, #ffa522)',
    borderRadius: '2px',
    fontSize: '16px',
    height: '42px',
    fontFamily: 'EEWBold',
    marginTop: '54px',
    cursor: 'pointer',
    '&:hover, &:focus': {
      outline: 'none',
    }
  },
  morelink: {
    color: '#000',
    textDecoration: 'none',
    backgroundImage: `url(${rightArrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
    paddingRight: '16px',
  },
  impressumBorder: {
    top: '45px',
    marginLeft: '-30px',
    position: 'relative',
    borderBottom: 'solid 1px #f29205',
    paddingBottom: '1px',
    maxWidth: 'fit-content',
    ':focus': {
      outline: 'none',
    }
  },
  impressum: {
    display: 'flex',
    color: '#ff9e28',
    fontSize: '0.8rem',
    textDecoration: 'none',
    fontFamily: 'EEWMedium',
    '&:focus, &:active': {
      outline: 'none',
    }
  },
});
