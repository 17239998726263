import axios from 'axios';
import * as constants from '../utils/constants';

export const userLogin = (userData) => {
  return axios({
    method: 'post', 
    url: constants.BASE_CONTEXT + '/auth/v1/Login',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: userData
  });
};

export const fetchExternalChartData = (requestObj) => {
  return axios({
    method: 'post', 
    url: constants.BASE_CONTEXT + '/deliveries/v1/Predictions',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data: requestObj
  });
};

export const fetchInternalChartData = (requestObj, authToken) => {
  return axios({
    method: 'post', 
    url: constants.BASE_CONTEXT + '/deliveries/v1/AvgDurationsHistory',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${authToken}`
    },
    data: requestObj
  });
};
