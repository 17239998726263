import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../../src/react-dates-overrride.css'
import { SingleDatePicker } from 'react-dates';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import styles from './DatePicker.styles';
import moment from 'moment';
import * as constants from '../../utils/constants';
import { isInclusivelyBeforeDay, isInclusivelyAfterDay } from '../../utils/datePickerUtils';
const useStyles = createUseStyles(styles);

const DatePicker = ({ dateChangeHandler }) => {
  const classes = useStyles();
  const currentDate = moment();
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [focused, setFocused] = useState(false);
  const dispatch = useDispatch();
  const minDate = moment().year(currentDate.year() - constants.YEAR_COUNT).startOf('year').subtract(1, 'days');
  const maxDate = moment().add(1, 'days');
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(true);

  const onNavIconClick = (e) => {
    if (e.target.classList.value.includes('disabledArrow')) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  const PrevButton = () => (
    <div className={classnames(classes.prevButton, disablePrev ? [classes.disabledArrow, 'disabledArrow'] : '')} onClick={onNavIconClick}></div>  
  );
  
  const NextButton = () => (
    <div className={classnames(classes.nextButton, disableNext ? [classes.disabledArrow, 'disabledArrow'] : '')} onClick={onNavIconClick}></div> 
  );

  const ShortcutPanel = () => (
    <div className={classes.shortcutButtonPanel} onClick= {() => handleDateChange(moment())}>
      <span><a href='#' onClick={(e) => e.preventDefault()}>Heute</a></span>
    </div>
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFocused(false);
    dateChangeHandler(date);
    const isPrevDisabled = !(moment(date).startOf('month').isBetween(moment(minDate), moment(maxDate)));
    const isNextDisabled = !(moment(date).endOf('month').isBetween(moment(minDate), moment(maxDate)));
    setDisablePrev(isPrevDisabled);
    setDisableNext(isNextDisabled);
  }

  const handleWeekMonthTxt = (day) => {
    //Change week day with custom day array
    day._locale._weekdaysMin = constants.DAYS_DE_FORMAT_DD;
    day._locale._months = constants.MONTHS_DE_FORMAT_MMMM;
    return (day.format('D'));
  }
  
  const onPrevMonthClick = (val) => {
    const isPrevDisabled = !(moment(val).startOf('month').isBetween(moment(minDate), moment(maxDate)));
    setDisablePrev(isPrevDisabled);
    setDisableNext(false);
  }

  const onNextMonthClick = (val) => {
    const isNextDisabled = !(moment(val).endOf('month').isBetween(moment(minDate), moment(maxDate)));
    setDisablePrev(false);
    setDisableNext(isNextDisabled);
  }

  return (
    <SingleDatePicker
        id="date-picker" // PropTypes.string.isRequired,
        date={selectedDate} // momentPropTypes.momentObj or null
        onDateChange={date => handleDateChange(date)} // PropTypes.func.isRequired
        focused={focused} // PropTypes.bool
        onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
        numberOfMonths={1}
        displayFormat= 'DD.MM.YYYY'
        monthFormat="MMMM[, ]YYYY"
        firstDayOfWeek={1}
        showDefaultInputIcon
        enableOutsideDays={false}
        isOutsideRange = {day => isInclusivelyBeforeDay(day, minDate) || isInclusivelyAfterDay(day, maxDate)}
        hideKeyboardShortcutsPanel={true}
        inputIconPosition="after"
        navPrev={<PrevButton />}
        navNext={<NextButton />}
        onPrevMonthClick={onPrevMonthClick}
        onNextMonthClick={onNextMonthClick}
        renderMonthText={month => constants.MONTH_MAP_DE_FORMAT_MMMM[moment(month, 'MMMM').month()+1] + ' ' +moment(month, 'MMMM').format('YYYY')}
        renderWeekHeaderElement={(day) => constants.DAY_MAP_DE_FORMAT_DD[day] ? constants.DAY_MAP_DE_FORMAT_DD[day] : day}
        renderCalendarInfo={() => (<ShortcutPanel />)}
        />
  );
};

export default DatePicker;