import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import styles from './ChartUI.styles';
// import { monthData } from '../../resources/mockData';
import moment from 'moment';
import * as constants from '../../utils/constants';
import { getChartData } from '../../redux/selectors';

const useStyles = createUseStyles(styles);

const MonthView = ({selectedMonth, selectedFilterType}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const chartContainer = useRef(null);
  const monthData = useSelector(getChartData);
  const [ monthYear, setMonthYear ] = useState({month: moment(selectedMonth.month, 'MMMM').month(), year: selectedMonth.year});
  const [ filterType, setFilterType ] = useState(selectedFilterType);
  const dayMapDe = Object.assign(constants.DAY_MAP_DE_FORMAT_DD);
  const monthMapDe = constants.MONTH_MAP_DE_FORMAT_MMMM;
  const currentDate = moment().format('DD.MM.YYYY');
  
  const getBarClass = (entry) => {
    if(moment(entry.arrival_ts).format('DD.MM.YYYY') === (moment(currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY'))) {
      return 'selectedBar';
    }
    return '';
  }

  const getMonthData = () => {
    return monthMapDe[monthYear.month] + ' ' + monthYear.year;
  }

  const getXaxisData = (d) => {
    return moment(d.arrival_ts).format('DD') + ' ' + ((dayMapDe[moment(d.arrival_ts).format('dd')]) ? dayMapDe[moment(d.arrival_ts).format('dd')] : moment(d.arrival_ts).format('dd')) ;
  }

  const getAverageDuration = () => {
    if (selectedFilterType === 'Wartezeit') {
      return monthData.average_wait_duration;
    } else if (selectedFilterType === 'Entleerung') {
      return monthData.average_offload_duration;
    } else {//Gesamtdauer
      return monthData.average_total_duration;
    }
  }

  const getDuration = (d) => {
    if (selectedFilterType === 'Wartezeit') {
      return d.wait_duration;
    } else if (selectedFilterType === 'Entleerung') {
      return d.offload_duration;
    } else {//Gesamtdauer
      return d.total_duration;
    }
  }

  useEffect(() => {
    if (!(monthYear.month == selectedMonth.month 
        && monthYear.year == selectedMonth.year
        && selectedFilterType === filterType)) {
      d3.selectAll("svg > g, line, text").remove();
      setMonthYear({month: selectedMonth.month, year: selectedMonth.year});
      setFilterType(selectedFilterType);
    }

    if (monthData.entries.length > 0  && chartContainer.current) {
      const svg = d3.select('.svgChart');
      const margin = 0,
            width = 1226 - margin,
            height = 400 - margin;
      
      const xScale = d3.scaleBand()
                    .domain(monthData.entries.map(function(d) { return getXaxisData(d); }))
                    .range([0, width])
                    .paddingInner(0.2)
                    .paddingOuter(0);
      const yScale = d3.scaleLinear()
                    .domain([0, d3.max(monthData.entries, function(d) { return getDuration(d); })+20])
                    .range([height, 20]);

      const g = svg.append('g').attr('transform', 'translate(' + 40 + ',-' + 10 + ')');

      const g1 = g.append('g');
      g1.attr('transform', 'translate(0,' + height + ')')
        .attr('class', 'x axis')
        .call(d3.axisBottom(xScale))
        .selectAll('.x .tick text')
        .call(function(t){                
          t.each(function(d) {
            const self = d3.select(this);
            const s = self.text().split(' ');
            if(s[1].includes('Sa') || s[1].includes('So')) {
              self.attr('class', 'closed');
            } 
            self.text('');
            self.append('tspan')
              .attr('x', 0)
              .attr('dy','1em')
              .text(s[0]);
            self.append('tspan')
              .attr('x', 0)
              .attr('dy','1em')
              .text(s[1]);
          })
        })
        
      // g1.append('text')
      //   .attr('class', 'x-axis-indicator')
      //   .attr('y', 15)
      //   .attr('x', width+45)
      //   .attr('text-anchor', 'end')
      //   .attr('stroke', 'black')
      //   .text('Uhrzeit');
      
      const g2 = g.append('g');
      g2.attr('class', 'y axis')
        .attr('class', 'y axis')
        .call(d3.axisLeft().scale(yScale).ticks(10).tickSizeInner(-width).tickPadding(10))
        .append('text')
        .attr('class', 'y-axis-indicator')
        .attr('x', 22)
        .attr('y', 6)
        .attr('dy', '-1em')
        .attr('text-anchor', 'end')
        .attr('stroke', 'black')
        .text('In Minuten');
      g2.select(".tick text")
        .text('');
      
      if (getAverageDuration() > 0) {
        svg.append('svg:line')
          .attr('class', 'average-duration-indicator')
          .attr('x1', 40)
          .attr('x2', width+40)
          .attr('y1', yScale(getAverageDuration())-10)
          .attr('y2', yScale(getAverageDuration())-10)
          .attr('class', 'averageLine');
        
        svg.append('text')
          .attr('class', 'average-duration-indicator-text')
          .attr('x', width+40)
          .attr('y', yScale(getAverageDuration())-10)
          .attr('dy', '-0.5em')
          .attr('text-anchor', 'end')
          .attr('stroke', 'black')
          .text('DURCHSCHNITTLICHE AUFENTHALTSDAUER');
      }
      
      g.selectAll('.bar')
        .data(monthData.entries)
        .enter().append('rect')
        .attr('class', function(entry) {
          return 'bar ' + getBarClass(entry);
        })
        .attr('x', function(d) { return xScale(getXaxisData(d)); })
        .attr('y', function(d) { return yScale(getDuration(d)); })
        .attr('width', xScale.bandwidth())
        .attr('height', function(d) { return height - yScale(getDuration(d)); })
        .on('mouseover', function(entry) {
          tooltip.style('visibility', 'visible');
          tooltip.select('div')
                .html('<span style="font-family: EEWMedium; font-size: 20px">ø</span> ' + getDuration(entry) + ' MIN')
                .style('position', 'fixed')
                .style('left', (d3.event.pageX) + 'px')
                .style('top', (d3.event.pageY) + 'px');
          this.classList.add('activeBar');
        })
        .on('mouseleave', function(entry) {
          tooltip.style('visibility', 'hidden');
          this.classList.remove('activeBar');
        });
      
      const tooltip = d3.select('body').append('div')
        .attr('class', 'tooltip')
        .style('visibility', 'hidden');
      
      tooltip.append('rect')
        .attr('width', 30)
        .attr('height', 20)
        .attr('fill', '#fffff');

      tooltip.append('div')
        .attr('x', 15)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .attr('font-size', '1em')
        .attr('font-weight', 'bold');
    }
  },[monthData, selectedMonth, selectedFilterType, chartContainer.current])

  return (<section className={classnames(classes.trackingChart)}>
            <div className={classnames(classes.chartHeading)}>
              <label className={classnames(classes.heading)}>AUFENTHALTSDAUER IM MONAT</label>
              <br />
              <label className={classnames(classes.date)}>Anlage Helmstedt, {getMonthData()}</label>
            </div>
            <svg className='svgChart' ref={chartContainer}>
              <defs>
                <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" style={{stopColor:'#ffd006',stopOpacity:'1'}} />
                  <stop offset="100%" style={{stopColor:'#ffa522',stopOpacity:'1'}} />
                </linearGradient>
              </defs>
            </svg>
            <div className={classnames(classes.legendsContainer)}>
              <div className={classnames(classes.colorBoxNormal)}></div><label>Geöffnet</label>
              <div className={classnames(classes.colorBoxCurrent)}></div><label>Jetzt</label>
              <div className={classnames(classes.colorBoxHighlighted)}></div><label>Auswahl</label>
            </div>
          </section>);
}

export default MonthView;