import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../../src/react-dates-overrride.css'
import { SingleDatePicker } from 'react-dates';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import CustomSelect from '../custom/CustomSelect';
import styles from './DatePicker.styles';
import moment from 'moment';
import * as constants from '../../utils/constants';

const useStyles = createUseStyles(styles);

const YearPicker = ({dateChangeHandler}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentDate = moment();
  const [ selectedYear, setSelectedYear ] = useState(currentDate.year())
  let yearsOptions = [];
  for(let i =  selectedYear - constants.YEAR_COUNT; i <= selectedYear; i++) {
    yearsOptions.push(i);
  }
  
  const onYearChange = (value) => {
    setSelectedYear(value);
    dateChangeHandler(value);
  }

  return <CustomSelect selectedValue={selectedYear} options={yearsOptions} onChange={onYearChange} />
};

export default YearPicker;