export default ({
  shortcutButtonPanel: {
      paddingLeft: '25px',
      paddingBottom: '15px',
      color: '#000',
    '& a': {
      color: '#f29205',
      textDecoration: 'none',
      fontFamily: 'EEWMedium',
      fontSize: '14px',
      '&:focus, &:active': {
        outline: 'none',
      }
    },
    '& span': {     
      paddingBottom: '1px',
      borderBottom: 'solid 1px #f29205'
    },
  },
  actualMonth: {
    position: 'absolute',
    top: '17.5rem',
    zIndex: '999',
  },
  prevButton: {
    borderRadius: '2px',
    backgroundColor: 'rgb(255, 255, 255)',
    color: '#f29205',
    fontSize: '3rem',
    padding: '0px 3px',
    position: 'absolute',
    top: '3px',
    left: '32px',
    content: '""',
    '&focus': {
      outline: 'none',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '0px',
      top: '24px',
      border: 'solid #ffa522',
      borderWidth: '0 4px 4px 0px',
      borderRadius: '3px',
      display: 'inline-block',
      padding: '4px',
      transform: 'rotate(135deg)',
    },
  },
  nextButton: {
    borderRadius: '2px',
    backgroundColor: 'rgb(255, 255, 255)',
    color: '#f29205',
    fontSize: '3rem',
    padding: '0px 3px',
    position: 'absolute',
    top: '3px',
    right: '32px',
    content: '""',
    '&focus': {
      outline: 'none',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      right: '0px',
      top: '24px',
      border: 'solid #ffa522',
      borderWidth: '4px 0px 0px 4px',
      borderRadius: '3px',
      display: 'inline-block',
      padding: '4px',
      transform: 'rotate(135deg)',
    },
  },
  disabledArrow: {
    '&:after': {
      content: '""',
      position: 'absolute',
      right: '0px',
      top: '24px',
      cursor: 'not-allowed',
      border: 'solid #d3d3d3',
      borderWidth: '4px 0px 0px 4px',
      borderRadius: '3px',
      display: 'inline-block',
      padding: '4px',
      transform: 'rotate(135deg)',
    },
  },
  weekNumber: {
    position: 'relative',
    top: '1px',
    left: '32px',
    zIndex: '999',
    fontSize: '14px',
    fontFamily: 'EEWMedium',
  }
});
  

