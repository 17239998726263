import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { createUseStyles } from "react-jss";
import classnames from "classnames";
import styles from "./ChartUI.styles";
import moment from "moment";
import * as constants from "../../utils/constants";
import * as chartDataUtils from "../../utils/chartDataUtils";
import { getChartData, getPredictionChartData } from "../../redux/selectors";

const useStyles = createUseStyles(styles);

const DayChart = ({ selectedDate, selectedFilterType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const dayData = useSelector(getChartData);
  const dayPredictionData = useSelector(getPredictionChartData);
  const withExcludedPredictions = chartDataUtils.withExcludedPredictions(
    dayData,
    dayPredictionData
  );

  const [date, setDate] = useState(selectedDate);
  const [filterType, setFilterType] = useState(selectedFilterType);
  const chartContainer = useRef(null);
  const currentTime = moment().format("HH[:00]");
  const dayMapDe = { ...constants.DAY_MAP_DE_FORMAT_DDDD };

  const checkTimeslotInRange = (currentTimeSlot, entryTime) => {
    const isInRange =
      moment(entryTime, "HH:mm").isSameOrAfter(
        moment(currentTimeSlot.start, "HH:mm")
      ) &&
      moment(entryTime, "HH:mm").isBefore(moment(currentTimeSlot.end, "HH:mm"));
    return isInRange;
  };
  const getBarClass = (entry) => {
    const currentTime = moment().format("HH:mm");
    const entryTime = moment.utc(entry.arrival_ts).format("HH:mm");
    let currentTimeSlot;
    if (currentTime.split(":")[1] < "30") {
      currentTimeSlot = {
        start: currentTime.split(":")[0] + ":00",
        end: currentTime.split(":")[0] + ":30",
      };
    } else {
      currentTimeSlot = {
        start: currentTime.split(":")[0] + ":30",
        end:
          (parseInt(currentTime.split(":")[0]) + 1 < 10
            ? "0" + (parseInt(currentTime.split(":")[0]) + 1 + ":00")
            : parseInt(currentTime.split(":")[0]) + 1 + ":00") + ":00",
      };
    }
    if (
      moment.utc(entry.arrival_ts).format("DD.MM.YYYY") ===
        moment().format("DD.MM.YYYY") &&
      checkTimeslotInRange(currentTimeSlot, entryTime)
    ) {
      return "selectedBar";
    }
    return "";
  };

  const getDay = (date) => {
    return dayMapDe[moment(date, "DD.MM.YYYY").format("dddd")];
  };

  const getAverageDuration = () => {
    if (selectedFilterType === "Wartezeit") {
      return dayData.average_wait_duration;
    } else if (selectedFilterType === "Entleerung") {
      return dayData.average_offload_duration;
    } else {
      //Gesamtdauer
      return dayData.average_total_duration;
    }
  };

  const getDuration = (d) => {
    if (selectedFilterType === "Wartezeit") {
      return d.wait_duration;
    } else if (selectedFilterType === "Entleerung") {
      return d.offload_duration;
    } else {
      //Gesamtdauer
      return d.total_duration;
    }
  };

  useEffect(() => {
    if (!(date == selectedDate && selectedFilterType === filterType)) {
      d3.selectAll("svg > g, line, text, circle").remove();
      setDate(selectedDate);
      setFilterType(selectedFilterType);
    }
    if (dayData.entries.length > 0 && chartContainer.current) {
      const svg = d3.select(".svgChart");
      let margin = 0,
        width = 1226 - margin,
        height = 400 - margin;

      if (!pathname.startsWith(constants.INTERNAL_PATH)) {
        if (window.screen.width === 320 && window.screen.height === 568) {
          margin = 0;
          width = 230 - margin;
          height = 260 - margin;
        }
      }

      let xScale;
      if (!pathname.startsWith(constants.INTERNAL_PATH)) {
        if (window.screen.width === 320 && window.screen.height === 568) {
          xScale = d3
            .scaleBand()
            .domain(
              dayData.entries.map(function (d) {
                return moment.utc(d.arrival_ts).format("HH:mm");
              })
            )
            .range([0, width])
            .paddingInner(0.1)
            .paddingOuter(0);
        } else {
          xScale = d3
            .scaleBand()
            .domain(
              dayData.entries.map(function (d) {
                return moment.utc(d.arrival_ts).format("HH:mm");
              })
            )
            .range([0, width])
            .paddingInner(0.05)
            .paddingOuter(0);
        }
      } else {
        xScale = d3
          .scaleBand()
          .domain(
            dayData.entries.map(function (d) {
              return moment.utc(d.arrival_ts).format("HH:mm");
            })
          )
          .range([0, width])
          .paddingInner(0.05)
          .paddingOuter(0);
      }
      let yScale;
      const maxYScaleRealData = d3.max(dayData.entries, function (d) {
        return getDuration(d);
      });
      if (
        selectedFilterType === "Gesamtdauer" &&
        pathname.startsWith(constants.INTERNAL_PATH) &&
        withExcludedPredictions &&
        Object.keys(withExcludedPredictions).length > 0
      ) {
        const maxYScalePredictionsData = d3.max(
          withExcludedPredictions.entries,
          function (d) {
            return getDuration(d);
          }
        );
        const maxYScale = Math.max(maxYScaleRealData, maxYScalePredictionsData);
        yScale = d3
          .scaleLinear()
          .domain([0, maxYScale + 20])
          .range([height, 20]);
      } else {
        yScale = d3
          .scaleLinear()
          .domain([0, maxYScaleRealData + 20])
          .range([height, 20]);
      }

      const g = svg
        .append("g")
        .attr("transform", "translate(" + 40 + ",-" + 10 + ")");
      const g1 = g.append("g");

      g1.attr("transform", "translate(0," + height + ")")
        .attr("class", "x axis")
        .call(d3.axisBottom(xScale))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-0.5em")
        .attr("y", 25);

      g1.selectAll(".tick line")
        .style("text-anchor", "end")
        .attr("dx", "-0.5em")
        .attr("dy", "-.55em")
        .attr("y", 20);

      if (
        !pathname.startsWith(constants.INTERNAL_PATH) &&
        window.screen.width === 320 &&
        window.screen.height === 568
      ) {
        g1.selectAll(".x .tick line").attr(
          "transform",
          "translate(-" + 3 + ",0)"
        );
      } else {
        g1.selectAll(".x .tick line").attr(
          "transform",
          "translate(-" + Math.abs(xScale.bandwidth() / 2) + ",0)"
        );
      }

      if (window.screen.width > 320 && window.screen.height > 568) {
        g1.append("text")
          .attr("class", "x-axis-indicator")
          .attr("y", 19)
          .attr("text-anchor", "end")
          .attr("stroke", "black")
          .text("Uhrzeit");
      }

      const g2 = g.append("g");
      g2.attr("class", "y axis")
        .call(
          d3
            .axisLeft()
            .scale(yScale)
            .ticks(10)
            .tickSizeInner(-width)
            .tickPadding(10)
        )
        .select(".tick text")
        .text("");

      if (
        !pathname.startsWith(constants.INTERNAL_PATH) &&
        window.screen.width === 320 &&
        window.screen.height === 568
      ) {
        g2.selectAll("text").attr("dx", "0.5em");
      }

      if (window.screen.width > 320 && window.screen.height > 568) {
        g2.append("text")
          .attr("class", "y-axis-indicator")
          .attr("x", 22)
          .attr("y", -6)
          .attr("dy", "1em")
          .attr("text-anchor", "end")
          .attr("stroke", "black")
          .text("In Minuten");
      }

      if (getAverageDuration() > 0) {
        svg
          .append("svg:line")
          .attr("class", "average-duration-indicator")
          .attr("x1", 40)
          .attr("x2", width + 40)
          .attr("y1", yScale(getAverageDuration()) - 10)
          .attr("y2", yScale(getAverageDuration()) - 10)
          .attr("class", "averageLine");

        svg
          .append("text")
          .attr("class", "average-duration-indicator-text desktop")
          .attr("x", width + 40)
          .attr("y", yScale(getAverageDuration()))
          .attr("dy", "-1.65em")
          .attr("text-anchor", "end")
          .attr("stroke", "black")
          .text("DURCHSCHNITTLICHE AUFENTHALTSDAUER");

        svg
          .append("text")
          .attr("class", "average-duration-indicator-text mobile")
          .attr("x", width + 40)
          .attr("y", yScale(getAverageDuration()))
          .attr("dy", "-1.9em")
          .attr("text-anchor", "end")
          .attr("stroke", "black")
          .text("DURCHSCHNITTLICHE AUFENTHALTSDAUER");
        //.text('Durchschnittliche Aufenthaltsdauer');
      }

      const slotsEndTime = moment
        .utc(dayData.entries[dayData.entries.length - 1].arrival_ts)
        .add(30, "minute")
        .format("HH");
      const lastArrivalEntrytime = moment
        .utc(dayData.entries[dayData.entries.length - 1].arrival_ts)
        .format("HH");
      let closingTime;
      if (slotsEndTime == lastArrivalEntrytime) {
        closingTime = ":30";
      } else {
        closingTime = ":00";
      }

      const closingG = g1
        .append("g")
        .attr("class", "tick")
        .attr(
          "transform",
          "translate(" + Math.abs(width + xScale.bandwidth() / 2) + ",0)"
        );
      closingG
        .append("line")
        .style("text-anchor", "end")
        .attr("dx", "-0.5em")
        .attr("dy", "-.55em")
        .attr("y", 19)
        .attr("y2", 6)
        .attr("stroke", "currentColor")
        .attr(
          "transform",
          "translate(-" + Math.abs(xScale.bandwidth() / 2) + ",0)"
        );

      closingG
        .append("text")
        .attr("class", "closing-time")
        .attr("y", 25)
        .attr("style", "text-anchor: end;")
        .attr("fill", "currentColor")
        .text(("0" + slotsEndTime).slice(-2) + closingTime);

      if (
        !pathname.startsWith(constants.INTERNAL_PATH) &&
        window.screen.width === 320 &&
        window.screen.height === 568
      ) {
        g1.selectAll("text")
          .attr("dy", "-2em")
          .attr(
            "transform",
            "translate(-" +
              Math.abs(xScale.bandwidth() / 2 - 11) +
              ",0) rotate(-45)"
          );
        g1.select("text.closing-time").attr(
          "style",
          "transform: translate(-13px, 6px) rotate(-45deg);"
        );
      } else {
        g1.selectAll("text")
          .attr("dy", "-.55em")
          .attr(
            "transform",
            "translate(-" + Math.abs(xScale.bandwidth() / 2 - 17) + ",0)"
          );
        g1.select("text.closing-time").attr(
          "transform",
          "translate(-" + Math.abs(xScale.bandwidth() / 2 - 13) + ",0)"
        );
      }
      g1.select("text.x-axis-indicator").attr(
        "transform",
        "translate(" + Math.abs(width + 45) + ",-10)"
      );

      g.selectAll(".bar")
        .data(dayData.entries)
        .enter()
        .append("rect")
        .attr("class", function (entry) {
          return "bar " + getBarClass(entry);
        })
        .attr("x", function (d) {
          return xScale(moment.utc(d.arrival_ts).format("HH:mm"));
        })
        .attr("y", function (d) {
          return yScale(getDuration(d));
        })
        .attr("width", xScale.bandwidth())
        .attr("height", function (d) {
          return height - yScale(getDuration(d));
        })
        .on("mouseover", function (entry) {
          tooltip.style("visibility", "visible");
          if (
            !pathname.startsWith(constants.INTERNAL_PATH) &&
            window.screen.width === 320 &&
            window.screen.height === 568
          ) {
            tooltip
              .select("div")
              .html("DAUER <br />CA. " + getDuration(entry) + " MIN")
              .style("position", "fixed")
              .style("left", () => {
                return d3.event.target.offsetLeft + "px";
              })
              .style("top", "172px");
          } else {
            tooltip
              .select("div")
              .html("DAUER CA. " + getDuration(entry) + " MIN")
              .style("position", "fixed")
              .style("left", d3.event.pageX + "px")
              .style("top", d3.event.pageY + "px");
          }
          d3.selectAll("svg > line.hoverLine").remove();
          this.classList.add("activeBar");
        })
        .on("mouseleave", function (entry) {
          tooltip.style("visibility", "hidden");
          this.classList.remove("activeBar");
          if (
            !pathname.startsWith(constants.INTERNAL_PATH) &&
            window.screen.width === 320 &&
            window.screen.height === 568
          ) {
            d3.selectAll("svg > line.hoverLine").remove();
          }
        })
        .on("click", function (entry) {
          if (
            !pathname.startsWith(constants.INTERNAL_PATH) &&
            window.screen.width === 320 &&
            window.screen.height === 568
          ) {
            tooltip
              .select("div")
              .html("DAUER CA. " + getDuration(entry) + " MIN")
              .style("position", "fixed")
              .style("left", () => {
                let offsetLeft = d3.event.pageX + 82;
                if (offsetLeft > 280) {
                  return d3.event.pageX - 70 + "px";
                } else {
                  return d3.event.pageX - 20 + "px";
                }
              })
              .style("top", "172px");

            d3.selectAll("svg > line.hoverLine").remove();
            svg
              .append("svg:line")
              .attr("class", "hover-duration-indicator")
              .attr(
                "x1",
                xScale(moment.utc(entry.arrival_ts).format("HH:mm")) + 43
              )
              .attr(
                "x2",
                xScale(moment.utc(entry.arrival_ts).format("HH:mm")) + 43
              )
              .attr("y1", 10)
              .attr("y2", yScale(getDuration(entry)) - 10)
              .attr("class", "hoverLine");
          } else {
            tooltip
              .select("div")
              .html("DAUER CA. " + getDuration(entry) + " MIN")
              .style("position", "fixed")
              .style("left", d3.event.pageX + "px")
              .style("top", d3.event.pageY + "px");
          }
          this.classList.add("activeBar");
        });

      const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("visibility", "hidden");

      tooltip
        .append("rect")
        .attr("width", 30)
        .attr("height", 20)
        .attr("fill", "#fffff");

      tooltip.append("div").attr("x", 15).attr("dy", "1em");

      //Prediction Chart
      if (selectedFilterType === "Gesamtdauer") {
        if (
          pathname.startsWith(constants.INTERNAL_PATH) &&
          withExcludedPredictions &&
          Object.keys(withExcludedPredictions).length > 0
        ) {
          let index;
          for (
            index = 0;
            index < withExcludedPredictions.entries.length - 1;
            index++
          ) {
            if (withExcludedPredictions.entries[index]) {
              svg
                .append("svg:line")
                .attr("class", "line")
                .attr(
                  "x1",
                  xScale(
                    moment
                      .utc(withExcludedPredictions.entries[index].arrival_ts)
                      .format("HH:mm")
                  ) +
                    40 +
                    xScale.bandwidth() / 2
                )
                .attr(
                  "x2",
                  xScale(
                    moment
                      .utc(
                        withExcludedPredictions.entries[index + 1].arrival_ts
                      )
                      .format("HH:mm")
                  ) +
                    40 +
                    xScale.bandwidth() / 2
                )
                .attr(
                  "y1",
                  yScale(getDuration(withExcludedPredictions.entries[index])) -
                    10
                )
                .attr(
                  "y2",
                  yScale(
                    getDuration(withExcludedPredictions.entries[index + 1])
                  ) - 10
                );

              svg
                .append("circle")
                .attr("class", "dot")
                .attr("cx", function (d, i) {
                  return (
                    xScale(
                      moment
                        .utc(withExcludedPredictions.entries[index].arrival_ts)
                        .format("HH:mm")
                    ) +
                    40 +
                    xScale.bandwidth() / 2
                  );
                })
                .attr("cy", function (d) {
                  return (
                    yScale(
                      getDuration(withExcludedPredictions.entries[index])
                    ) - 10
                  );
                })
                .attr("r", 5)
                .attr(
                  "data-duration",
                  withExcludedPredictions.entries[index].total_duration
                )
                .on("click", function (entry) {
                  tooltipForeCast
                    .select("div")
                    .html(this.dataset.duration + " MIN")
                    .style("position", "fixed")
                    .style("left", d3.event.pageX + "px")
                    .style("top", d3.event.pageY + "px");
                })
                .on("mouseover", function (entry) {
                  tooltipForeCast.style("visibility", "visible");
                  tooltipForeCast
                    .select("div")
                    .html(this.dataset.duration + " MIN")
                    .style("position", "fixed")
                    .style("left", d3.event.pageX + "px")
                    .style("top", d3.event.pageY + "px");
                  this.classList.add("activeBar");
                })
                .on("mouseleave", function (entry) {
                  tooltipForeCast.style("visibility", "hidden");
                  this.classList.remove("activeBar");
                });
            }
          }

          svg
            .append("circle")
            .attr("class", "dot")
            .attr("cx", function (d, i) {
              return (
                xScale(
                  moment
                    .utc(withExcludedPredictions.entries[index].arrival_ts)
                    .format("HH:mm")
                ) +
                40 +
                xScale.bandwidth() / 2
              );
            })
            .attr("cy", function (d) {
              return (
                yScale(getDuration(withExcludedPredictions.entries[index])) - 10
              );
            })
            .attr("r", 5)
            .attr(
              "data-duration",
              withExcludedPredictions.entries[index].total_duration
            )
            .on("click", function (entry) {
              tooltipForeCast
                .select("div")
                .html(this.dataset.duration + " MIN")
                .style("position", "fixed")
                .style("left", d3.event.pageX + "px")
                .style("top", d3.event.pageY + "px");
            })
            .on("mouseover", function (entry) {
              tooltipForeCast.style("visibility", "visible");
              tooltipForeCast
                .select("div")
                .html(this.dataset.duration + " MIN")
                .style("position", "fixed")
                .style("left", d3.event.pageX + "px")
                .style("top", d3.event.pageY + "px");
              this.classList.add("activeBar");
            })
            .on("mouseleave", function (entry) {
              tooltipForeCast.style("visibility", "hidden");
              this.classList.remove("activeBar");
            });
        }
      }

      const tooltipForeCast = d3
        .select("body")
        .append("div")
        .attr("class", "tooltipForecast")
        .style("visibility", "hidden");

      tooltipForeCast
        .append("rect")
        .attr("width", 30)
        .attr("height", 20)
        .attr("fill", "#fffff");

      tooltipForeCast.append("div").attr("x", 15).attr("dy", "1em");

      g.selectAll(".bar").exit().remove();
    }
  }, [dayData, selectedDate, selectedFilterType, chartContainer.current]);

  return (
    <section className={classnames(classes.trackingChart)}>
      <div className={classnames(classes.chartHeading)}>
        {pathname.startsWith(constants.INTERNAL_PATH) ? (
          <>
            <label className={classnames(classes.heading)}>
              AUFENTHALTSDAUER AM TAG
            </label>
            <br />
            <label className={classnames(classes.date)}>
              Anlage Helmstedt, {getDay(date)}, {date}
            </label>
          </>
        ) : (
          <>
            <label
              className={classnames(classes.heading, classes.desktopVisible)}
            >
              ANLAGE HELMSTEDT, ERWARTETE AUFENTHALTSDAUER HEUTE
            </label>
            <label
              className={classnames(classes.heading, classes.mobileVisible)}
            >
              Anlage Helmstedt, Erwartete Aufenthaltsdauer Heute
            </label>
            <label className={classnames(classes.date)}>
              {getDay(date)}, {date}
            </label>
          </>
        )}
      </div>
      <svg className="svgChart" ref={chartContainer}>
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop
              offset="0%"
              style={{ stopColor: "#ffd006", stopOpacity: "1" }}
            />
            <stop
              offset="100%"
              style={{ stopColor: "#ffa522", stopOpacity: "1" }}
            />
          </linearGradient>
        </defs>
      </svg>
      <div className={classnames(classes.legendsContainer)}>
        <div className={classnames(classes.colorBoxNormal)}></div>
        <label>Geöffnet</label>
        <div className={classnames(classes.colorBoxCurrent)}></div>
        <label>Jetzt</label>
        <div className={classnames(classes.colorBoxHighlighted)}></div>
        <label>Auswahl</label>
        {pathname.startsWith(constants.INTERNAL_PATH) &&
        selectedFilterType === "Gesamtdauer" ? (
          <>
            <div className={classnames(classes.colorBoxPredictions)}></div>
            <label>Vorhersage</label>
          </>
        ) : null}
      </div>
    </section>
  );
};

export default DayChart;
