import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import styles from './Loader.styles';

const useStyles = createUseStyles(styles);

const Loader = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classnames(classes.loaderContainer)}>
      <div className={classnames('lds-default')}>
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        <br />
        <label className={classnames(classes.loderText)}>Daten werden geladen</label>
      </div>
    </div>
	);
};

export default Loader;