export const EXTERNAL_PATH = '/';
export const INTERNAL_PATH = '/eew-digital';
export const INTERNAL_IMPRINT_PATH = '/eew-digital/meta/imprint.html';
export const EXTERNAL_IMPRINT_PATH = '/eew/meta/imprint.html';
export const INTERNAL_DATA_PROTECTION_PATH = '/eew-digital/meta/data-protection.html';
export const EXTERNAL_DATA_PROTECTION_PATH = '/eew/meta/data-protection.html';
export const BASE_CONTEXT = process.env.REACT_APP_API_ENPOINT;
export const NO_DATA_FOUND = 'No Data Found';
export const API_ERROR = 'Api Error';
export const YEAR_COUNT = 4;
export const DAY_MAP_DE_FORMAT_DD = { 
  'Su':'So',
  'Mo':'Mo',
  'Tu':'Di',
  'We':'Mi',
  'Th':'Do',
  'Fr':'Fr',
  'Sa':'Sa' 
};
export const DAY_MAP_DE_FORMAT_DDDD = { 
  'Monday':'Montag',
  'Tuesday':'Dienstag',
  'Wednesday':'Mittwoch',
  'Thursday':'Donnerstag',
  'Friday':'Freitag',
  'Saturday':'Samstag',
  'Sunday':'Sonntag'
}
export const MONTH_MAP_DE_FORMAT_MMMM = {
  '1':'Januar',
  '2':'Februar',
  '3':'März',
  '4':'April',
  '5':'Mai',
  '6':'Juni',
  '7':'Juli',
  '8':'August',
  '9':'September',
  '10':'Oktober',
  '11':'November',
  '12':'Dezember'
};
export const MONTH_DE_FORMAT_MMMM_NUMBER_MAP = {
  'Januar': 1,
  'Februar': 2,
  'März': 3,
  'April': 4,
  'Mai': 5,
  'Juni': 6,
  'Juli': 7,
  'August': 8,
  'September': 9,
  'Oktober': 10,
  'November': 11,
  'Dezember': 12
};
export const DAYS_DE_FORMAT_DD = ['So','Mo','Di','Mi','Do','Fr','Sa'];
export const MONTHS_DE_FORMAT_MMMM = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember'
];