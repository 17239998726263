import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import { userLogin } from '../../redux/actions';
import styles from './Login.styles';
import * as utils from '../../utils/utils';
import * as constants from '../../utils/constants';

const useStyles = createUseStyles(styles);

const Login = ({isAuthenticated}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [ email, setEmail ] = useState({ value: '', isEmpty: false, isInvalid: false });
  const [ password, setPassword ] = useState({ value: '', isEmpty: false, isInvalid: false });
  const [ isValidForm , setIsValidForm ] = useState(isAuthenticated);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [ savePasswordSelected, setSavePasswordSelected] = useState(false);

  useEffect(() => {
    const savedUserData = utils.checkCookie();
    if (savedUserData && savedUserData.email && savedUserData.password) {
      setEmail({ value: savedUserData.email, isEmpty: false, isInvalid: false });
      setPassword({ value: savedUserData.password, isEmpty: false, isInvalid: false });
    }
  }, []);

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const onEmailChange = (e) => {
    utils.deleteSessionObj();
    setIsValidForm('valid');
    if(e.target.value !== '') {
      const isValidEmail = validateEmail(e.target.value);
      if (isValidEmail) {
        setEmail({ value: e.target.value, isEmpty: false, isInvalid: false });
      }
      else {
        setEmail({ value: e.target.value, isEmpty: false, isInvalid: false });// isInvalid: true 
      }
    }
    else {
      setEmail({ value: e.target.value, isEmpty: true, isInvalid: false });
    }
  }

  const onPasswordChange = (e) => {
    utils.deleteSessionObj();
    setIsValidForm('valid');
    if(e.target.value !== '') {
      setPassword({ value: e.target.value, isEmpty: false, isInvalid: false });
    }
    else {
      setPassword({ value: e.target.value, isEmpty: true, isInvalid: false });
    }
  }

  const onSavePasswordChange = (e) => {
    const savePasswordChecked = e.target.checked;
    emailRef.current.attributes['autocomplete'].value = savePasswordChecked;
    passwordRef.current.attributes['autocomplete'].value = savePasswordChecked;
    setSavePasswordSelected(savePasswordChecked);
  }
  
  const validateForm = () => {
    if(email.value === '') setEmail({ ...email, isEmpty: true });
    if(password.value === '') setPassword({ ...password, isEmpty: true });
    return (email.value !== '' && password.value !== '');
  }

  const onLogin = (e) => {
    e.preventDefault();
    //valid input data > { email: 'test@test.com', password: 'Waste2EnergyIsGreat!' }
    if(validateForm()) {
      dispatch(userLogin({ email: email.value, password: password.value, savePassword: savePasswordSelected }));
    }
  }

  const getErrorMsg = () => {
    if(email.isEmpty && password.isEmpty) {
      return 'Bitte Email und Passwort eingeben.';
    } else if(email.isEmpty && !password.isEmpty) {
      return 'Bitte Email eingeben.';
    } else if (!email.isEmpty && password.isEmpty) {
      return 'Bitte Passwort eingeben.';
    }
  }

  return (
    <section className={classnames(classes.loginContainer)}>
      <div className={classnames(classes.loginForm)}>
        <label className={classnames(classes.formTitle)}>Anmeldung</label>
        {isValidForm === 'invalid' ? 
        <label className={classnames(classes.invalidDataLabel, classes.visible)}>Bitte geben Sie gültige Anmeldeinformationen ein.</label>
        :<label className={classnames(classes.invalidDataLabel, (email.isEmpty || password.isEmpty) ? classes.visible : classes.hidden)}>{getErrorMsg()}</label>}
        <label className={classnames(classes.inputLabel, classes.email)}>Benutzername</label>
        <input type='text' ref={emailRef} name='email' className={classnames((email.isEmpty || email.isInvalid) ? classes.inputError: '')} autoComplete='off' value={email.value} onChange={onEmailChange} />
        {/* <label className={classnames(classes.errorLabel, email.isEmpty ? classes.visible : classes.hidden)}>Please enter email.</label>
        <label className={classnames(classes.errorLabel, email.isInvalid ? classes.visible : classes.hidden)}>Invalid email.</label> */}
        <label className={classnames(classes.inputLabel, classes.password)}>Passwort</label>
        <input type='password' ref={passwordRef} name='password' className={classnames((password.isEmpty) ? classes.inputError: '')} autoComplete='off' value={password.value} onChange={onPasswordChange} />
        {/* <label className={classnames(classes.errorLabel, password.isEmpty ? classes.visible : classes.hidden)}>Please enter password.</label>
        <label className={classnames(classes.errorLabel, password.isEmpty ? classes.visible : classes.hidden)}>Invalid password.</label> */}
        <label className={classnames('savePassword',classes.savePassword)}>
          <input type='checkbox' id='savePassword' name='savePassword' value='savePassword' onChange={onSavePasswordChange} selected={savePasswordSelected}/><span className="checkmark"></span><span>Passwort speichern</span>
        </label>
        <button className={classnames(classes.loginButton)} onClick={onLogin}>Anmelden</button>
        <label className={classnames(classes.infoLabel)}><span>Bei Fragen bitte an&nbsp;</span><a href="mailto:digital@eew-energyfromwaste.com" className={classnames(classes.morelink)}>digital@eew-energyfromwaste.com</a></label>
        <span className={classnames(classes.impressumBorder)}>
          <a href={pathname.startsWith(constants.INTERNAL_PATH) ? constants.INTERNAL_IMPRINT_PATH : constants.EXTERNAL_IMPRINT_PATH} className={classnames(classes.impressum)}>Impressum</a>
        </span>
      </div>
      
    </section>
  );
};

export default Login;