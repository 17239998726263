import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import styles from './ChartUI.styles';
// import { weekData } from '../../resources/mockData';
import moment from 'moment';
import * as constants from '../../utils/constants';
import { getChartData } from '../../redux/selectors';

const useStyles = createUseStyles(styles);

const WeekView = ({ selectedWeek, selectedFilterType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const weekData = useSelector(getChartData);
  const [ weekRange, setWeekRange ] = useState({...selectedWeek});
  const [ filterType, setFilterType ] = useState(selectedFilterType);
  const chartContainer = useRef(null);
  const currentDay = moment.utc().format('dddd');//Wednesday
  const dayMapDe = constants.DAY_MAP_DE_FORMAT_DDDD;
  const calculateActiveWeek = date => {
    const mon = date.clone().startOf("isoweek");
    const tue = mon.clone().add(1, "d");
    const wed = mon.clone().add(2, "d");
    const thu = mon.clone().add(3, "d");
    const fri = mon.clone().add(4, "d");
    const sat = mon.clone().add(5, "d");
    const sun = mon.clone().add(6, "d");
    return [sun, mon, tue, wed, thu, fri, sat];
  };
  const dayMap = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  };

  const getBarClass = (entry) => {
    if((moment.utc(entry.arrival_ts).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY'))
      && (dayMapDe[moment.utc(entry.arrival_ts).format('dddd')] === dayMapDe[currentDay])) {
      return 'selectedBar';
    }
    return '';
  }

  const getDay = (d) => {
    return dayMapDe[moment.utc(d.arrival_ts).format('dddd')];
  }

  const getWeekRangeLabel = () => {
    return moment(weekRange.startDate, 'DD.MM.YYYY').startOf('isoWeek').format('DD') 
          + ' - ' 
          + moment(weekRange.endDate, 'DD.MM.YYYY').endOf('isoWeek').format('DD.MM.YYYY');
  }

  const getAverageDuration = () => {
    if (selectedFilterType === 'Wartezeit') {
      return weekData.average_wait_duration;
    } else if (selectedFilterType === 'Entleerung') {
      return weekData.average_offload_duration;
    } else {//Gesamtdauer
      return weekData.average_total_duration;
    }
  }

  const getDuration = (d) => {
    if (selectedFilterType === 'Wartezeit') {
      return d.wait_duration;
    } else if (selectedFilterType === 'Entleerung') {
      return d.offload_duration;
    } else {//Gesamtdauer
      return d.total_duration;
    }
  }

  const getTotalDuration = (d) => {
    return d.total_duration;
  }

  const chkForClosedData = (d) => {
    const currentDate = moment();
    const currentWeekDays = calculateActiveWeek(currentDate);
    const currentWeekEndDate = moment(currentWeekDays[6]).add(2, 'days');
    const isFutureDate = moment(d.arrival_ts).isAfter(moment(currentDate));
    if(isFutureDate) {
      const day = dayMap[moment(d.arrival_ts).isoWeekday()];
      if(day === 'Sunday') {
        return true;
      }
      return false;
    };
    return true;
  }

  useEffect(() => {
    if (!(weekRange.startDate == selectedWeek.startDate
        && weekRange.endDate == selectedWeek.endDate 
        && selectedFilterType === filterType)) {
      d3.selectAll("svg > g, line, text").remove();
      setWeekRange({...selectedWeek});
      setFilterType(selectedFilterType);
    }

    if (weekData.entries.length > 0 && chartContainer.current) {
      const svg = d3.select('.svgChart');
      const margin = 0,
            width = 1226 - margin,
            height = 400 - margin;

      const xScale = d3.scaleBand()
                    .domain(weekData.entries.map(function(d) { return getDay(d); }))
                    .range([0, width])
                    .paddingInner(0.1)
                    .paddingOuter(0);
      const yScale = d3.scaleLinear()
                    .domain([0, d3.max(weekData.entries, function(d) { return getDuration(d); })+20])
                    .range([height, 20]);

      const g = svg.append('g').attr('transform', 'translate(' + 40 + ',-' + 10 + ')');

      g.append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .attr('class', 'x axis')
        .call(d3.axisBottom(xScale))
        .append('text')
        // .attr('class', 'x-axis-indicator')
        // .attr('y', 15)
        // .attr('x', width + 45)
        // .attr('text-anchor', 'end')
        // .attr('stroke', 'black')
        // .text('Uhrzeit');

      const g1 = g.append('g');
      g1.attr('class', 'y axis')
        .call(d3.axisLeft().scale(yScale).ticks(10).tickSizeInner(-width).tickPadding(10))
        .append('text')
        .attr('class', 'y-axis-indicator')
        .attr('x', 22)
        .attr('y', 6)
        .attr('dy', '-1em')
        .attr('text-anchor', 'end')
        .attr('stroke', 'black')
        .text('In Minuten');
      g1.select(".tick text")
        .text('');

      if (getAverageDuration() > 0) {
        svg.append('svg:line')
          .attr('class', 'average-duration-indicator')
          .attr('x1', 40)
          .attr('x2', width+40)
          .attr('y1', yScale(getAverageDuration())-10)
          .attr('y2', yScale(getAverageDuration())-10)
          .attr('class', 'averageLine');
        
        svg.append('text')
          .attr('class', 'average-duration-indicator-text')
          .attr('x', width+40)
          .attr('y', yScale(getAverageDuration())-10)
          .attr('dy', '-0.5em')
          .attr('text-anchor', 'end')
          .attr('stroke', 'black')
          .text('DURCHSCHNITTLICHE AUFENTHALTSDAUER');
      }
      g.selectAll('.bar')
        .data(weekData.entries)
        .enter().append('rect')
        .attr('class', function(entry) {
          return 'bar ' + getBarClass(entry);
        })
        .attr('x', function(d) { return xScale(getDay(d)); })
        .attr('y', function(d) { return yScale(getDuration(d)); })
        .attr('width', xScale.bandwidth())
        .attr('height', function(d) {
          const isClosed = chkForClosedData(d);
          if(isClosed && getTotalDuration(d) == 0) {
            svg.append('text')
            .attr('class', 'no-data-indicator-text')
            .attr('x', xScale(getDay(d))+xScale.bandwidth())
            .attr('y', yScale(5))
            .attr('dy', '-0.5em')
            .attr('text-anchor', 'end')
            .attr('stroke', 'black')
            .text('GESCHLOSSEN');
          }
          return height - yScale(getDuration(d)); 
        })
        .on('mouseover', function(entry) {
          tooltip.style('visibility', 'visible');
          tooltip.select('div')
                .html('<span style="font-family: EEWMedium; font-size: 20px">ø</span> ' + getDuration(entry) + ' MIN')
                .style('position', 'fixed')
                .style('left', (d3.event.pageX) + 'px')
                .style('top', (d3.event.pageY) + 'px');
          this.classList.add('activeBar');
        })
        .on('mouseleave', function(entry) {
          tooltip.style('visibility', 'hidden');
          this.classList.remove('activeBar');
        });
      
      const tooltip = d3.select('body').append('div')
        .attr('class', 'tooltip')
        .style('visibility', 'hidden');
      
      tooltip.append('rect')
        .attr('width', 30)
        .attr('height', 20)
        .attr('fill', '#fffff');

      tooltip.append('div')
        .attr('x', 15)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .attr('font-size', '1em')
        .attr('font-weight', 'bold');
    }
  },[weekData, selectedWeek, selectedFilterType, chartContainer.current])

  return (<section className={classnames(classes.trackingChart)}>
            <div className={classnames(classes.chartHeading)}>
              <label className={classnames(classes.heading)}>AUFENTHALTSDAUER IN DER WOCHE</label>
              <br />
<label className={classnames(classes.date)}>Anlage Helmstedt, {getWeekRangeLabel()}</label>
            </div>
            <svg className='svgChart' ref={chartContainer}>
              <defs>
                <linearGradient id='grad1' x1='0%' y1='0%' x2='0%' y2='100%'>
                  <stop offset='0%' style={{stopColor:'#ffd006',stopOpacity:'1'}} />
                  <stop offset='100%' style={{stopColor:'#ffa522',stopOpacity:'1'}} />
                </linearGradient>
              </defs>
            </svg>
            <div className={classnames(classes.legendsContainer)}>
              <div className={classnames(classes.colorBoxNormal)}></div><label>Geöffnet</label>
              <div className={classnames(classes.colorBoxCurrent)}></div><label>Jetzt</label>
              <div className={classnames(classes.colorBoxHighlighted)}></div><label>Auswahl</label>
            </div>
          </section>);
}

export default WeekView;

