import React from 'react';
import { useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import { userLogout } from '../../redux/actions';
import styles from './Header.styles';
import * as constants from '../../utils/constants';
import { useLocation } from 'react-router-dom';

const useStyles = createUseStyles(styles);

const Header = ({isAuthenticated}) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const logoutApplication = () => {
    dispatch(userLogout());
  }
  
  return (
    <header className={classnames(classes.headerContainer)}>
      <div className={classnames(pathname.startsWith(constants.INTERNAL_PATH) ? classes.logoDigital : classes.logoExternal)}></div>
      {isAuthenticated === 'valid' && pathname.startsWith(constants.INTERNAL_PATH) ?
      <div className={classnames(classes.actions)}>
        <button className={classnames(classes.logoutButton)} onClick={logoutApplication}>Abmelden</button>
      </div>
      :null}
    </header>
  );
};

export default Header;