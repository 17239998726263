import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import styles from './ErrorHandler.styles';
import { getError } from '../../../redux/selectors';
import * as constants from '../../../utils/constants';

const useStyles = createUseStyles(styles);

const ErrorHandler = ({selectedView, loadChart}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { type } = useSelector(getError);

  const updateClickHandler = () => {
    loadChart(selectedView);
  }

  return (
    <div className={classnames(classes.errorContainer)}>
      { type === constants.NO_DATA_FOUND ?
      <div className={classnames(classes.errorSection)}>
        <label className={classnames(classes.heading)}>Keine Daten gefunden</label>
        <label className={classnames(classes.errorText, classes.noDataFoundTxt)}>Für diesen Zeitraum können keine Daten angezeigt werden</label>
      </div>
      :
      <div className={classnames(classes.errorSection)}>
        <label className={classnames(classes.heading)}>Daten können nicht geladen werden</label>
        <label className={classnames(classes.errorText)}>Derzeit können die Daten zur Aktualisierung unserer Service-Website nicht geladen werden. Bitte versuche es erneut.</label>
        <button className={classnames(classes.updateButton)} onClick={updateClickHandler}>Aktualisieren</button>
      </div>}
    </div>
	);
};

export default ErrorHandler;