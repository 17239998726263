import * as types from './types';
import * as api from '../../api';
import * as utils from '../../utils/utils';
import * as constants from '../../utils/constants';
import * as chartDataUtils from '../../utils/chartDataUtils';

export const updateChartData = (chartData) => {
  return {
    type: types.UPDATE_CHART_DATA,
    chartData
  };
}

export const updatePredictionChartData = (predictionChartData) => {
  return {
    type: types.UPDATE_PREDICTION_CHART_DATA,
    predictionChartData
  };
}

export const showLoader = () => {
  return {
    type: types.SHOW_LOADER,
    loader: {show: true}
  };
}

export const hideLoader = () => {
  return {
    type: types.HIDE_LOADER,
    loader: {show: false}
  };
}

export const showError = (type) => {
  return {
    type: types.SHOW_ERROR,
    error: {show: true, type}
  };
}

export const hideError = () => {
  return {
    type: types.HIDE_ERROR,
    error: {show: false, type: ''}
  };
}

export const userLogin = ({email, password, savePassword}) => {
  return (dispatch) => {
    //{ email: 'test@test.com', password: 'Waste2EnergyIsGreat!' }
    api.userLogin({email, password})
      .then(({ status, data:{Token} }) => {
        if (status === 200) {
          if(savePassword) {
            utils.setCookie('eew-digital-email', email, 30);
            utils.setCookie('eew-digital-password', password, 30);
          }
          utils.setSessionObjKey('isAuthenticated', 'valid');
          utils.setSessionObjKey('bearerAuthToken', Token);
          window.location = constants.INTERNAL_PATH;
        }
      })
      .catch((error) => {
        const { status, statusText, data:{Msg} } = error.response;
        if (status === 401 && statusText === 'Unauthorized') {
          utils.setSessionObjKey('isAuthenticated', 'invalid');
          window.location = constants.INTERNAL_PATH;
        }
      });
  };
};

export const userLogout = (userData) => {
  return () => {
    utils.deleteSessionObj();
    window.location = constants.INTERNAL_PATH;
  };
};

export const fetchExternalChartData = (requestObj, view, type) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch(hideError());
    const authToken = utils.getSessionObjKey('bearerAuthToken');
    api.fetchExternalChartData(requestObj, authToken)
      .then(async ({ status, data }) => {
        setTimeout(() => {dispatch(hideLoader())}, 1000);
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = await chartDataUtils.processChartData(data, requestObj, view, 'external');
            if(type === 'show-predictions') {
              dispatch(updatePredictionChartData(updatedData));
            } else {
              dispatch(updateChartData(updatedData));
            }
          } else {
            if(type === 'show-predictions') {
              dispatch(updatePredictionChartData([]));
            } else {
              dispatch(showError(constants.NO_DATA_FOUND));
              dispatch(updateChartData([]));
            }
          }
        } else {
          dispatch(showError(constants.API_ERROR));
        }
      })
      .catch((error) => {
        // const { status, statusText, data:{Msg} } = error.response;
        setTimeout(() => {dispatch(hideLoader())}, 1000);
        dispatch(showError(constants.API_ERROR));
      });
  };
};

export const fetchInternalChartData = (requestObj, view) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch(hideError());
    const authToken = utils.getSessionObjKey('bearerAuthToken');
    api.fetchInternalChartData(requestObj, authToken)
      .then(async ({ status, data }) => {
        setTimeout(() => {dispatch(hideLoader())}, 1000);
        if (status === 200) {
          if (data.length > 0) {
            const updatedData = await chartDataUtils.processChartData(data, requestObj, view, 'internal');
            dispatch(updateChartData(updatedData));
            dispatch(hideError());
          } else {
            dispatch(updateChartData([]));
            dispatch(showError(constants.NO_DATA_FOUND));
          }        
        } else {
          dispatch(showError(constants.API_ERROR));
        }
      })
      .catch((error) => {
        // const { status, statusText, data:{Msg} } = error.response;
        setTimeout(() => {dispatch(hideLoader())}, 1000);
        dispatch(showError(constants.API_ERROR));
      });
  };
};