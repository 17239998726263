export default ({
  loaderContainer: {
    display: 'flex',
    backgroundColor: '#fff',
    height: '620px',
    minHeight: '550px',
    flexDirection: 'column',
    marginBottom: '22px',
    width: '1330px',
    flex: '1 auto',
  },
  loderText: {
    color: '#ff9e28',
    fontSize: '0.8rem',
    fontFamily: 'EEWMedium',
    marginTop: '70px',
    position: 'absolute',
    marginLeft: '-18px',
    width: 'max-content',
  },
  '@media screen and (max-width: 320px)': {
    loaderContainer: {
      width: '100%',
      height: '420px',
      minHeight: '450px',
    },
    loderText: {
      width: '300px',
      height: '168px',
    },
    errorText: {
      width: '300px',
    },
    noDataFoundTxt: {
      width: '300px',
      fontSize: '16px',
    },
  },
});