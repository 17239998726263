import moment from 'moment';

const getGenTime = (timeString) => {
  let H = +timeString.substr(0, 2);
  let h = (H % 24) || 12;
  return timeString = h + timeString.substr(2, 3);
}

const returnTimesInBetween = (start, end) => {
  var timesInBetween = [];

  var startH = parseInt(start.split(":")[0]);
  var startM = parseInt(start.split(":")[1]);
  var endH = parseInt(end.split(":")[0]);
  var endM = parseInt(end.split(":")[1]);

  timesInBetween.push(start);
  if (startM == 30)
    startH++;

  for (var i = startH; i < endH; i++) {
    timesInBetween.push(i < 10 ? "0" + i + ":00" : i + ":00");
    timesInBetween.push(i < 10 ? "0" + i + ":30" : i + ":30");
  }

  timesInBetween.push(endH < 10 ? "0" + i + ":00" : i + ":00");
  if (endM == 30)
    timesInBetween.push(endH < 10 ? "0" + i + ":30" : i + ":30")

  return timesInBetween;
}

const addMissingDays = (data, requestObj, view) => { //To Do
  let emptyObject = {
    arrival_ts: '',
    wait_duration: 0,
    offload_duration: 0,
    total_duration: 0,
    deliveries_amount: 0,
    Forecast: false
  };
  const modifiedData = [];
  if (view === 'Tag') {
    let hourSlots = returnTimesInBetween(moment.utc(data[0].arrival_ts).format('HH:mm'), '19:30') //moment.utc(data[data.length-1].arrival_ts).format('HH:mm')
    for (let index = 0, dataIndex = 0; index < hourSlots.length; index++, dataIndex++) {
      const [hours, minutes] = hourSlots[index].split(':');
      if(dataIndex < data.length) {
        const entrySlot = moment.utc(data[dataIndex].arrival_ts).format('HH:mm');
        if(entrySlot !== hourSlots[index]) {
          const dateValue = moment.utc(data[dataIndex].arrival_ts);
          const hourValue = hourSlots[index].split(':')[0];
          const minValue = hourSlots[index].split(':')[1];
          const modifiedDate = moment.utc(dateValue.hours(hourValue).minutes(minValue)).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
          dataIndex--;
          modifiedData[index] = {...emptyObject, arrival_ts: modifiedDate};
        } else {
          modifiedData[index] = data[dataIndex];
        }
      } else {
        const dateValue = moment.utc(data[0].arrival_ts);
        const hourValue = hourSlots[index].split(':')[0];
        const minValue = hourSlots[index].split(':')[1];
        const modifiedDate = moment.utc(dateValue.hours(hourValue).minutes(minValue)).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
        modifiedData[index] = {...emptyObject, arrival_ts: modifiedDate};
      }
    }
  }
  else if (view === 'Woche') {//moment.utc(d.arrival_ts).format('dddd')
    const daysInWeekRange = [];
    let dayCount = 1;
    daysInWeekRange.push(moment(requestObj.from).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'))
    while (dayCount < 7) {
      daysInWeekRange.push(moment(requestObj.from).add(dayCount, 'days').startOf('day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'));
      dayCount++;
    }
    
    for (let index = 0, dataIndex = 0; index < daysInWeekRange.length; index++, dataIndex++) {
      if(dataIndex < data.length) {
        const entrySlot = moment.utc(data[dataIndex].arrival_ts).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
        if(!moment.utc(entrySlot).isSame(moment.utc(daysInWeekRange[index]))) {
          dataIndex--;
          emptyObject.arrival_ts = daysInWeekRange[index];
          modifiedData[index] = {...emptyObject};;
          emptyObject.arrival_ts = ''; 
        } else {
          modifiedData[index] = data[dataIndex];
        }
      } else {
        emptyObject.arrival_ts = daysInWeekRange[index];
        modifiedData[index] = {...emptyObject};
        emptyObject.arrival_ts = ''; 
      }
    }
  }
  else if (view === 'Monat') {//moment.utc(d.arrival_ts).format('dddd')
    const daysInMonthRange = [];
    let dayCount = 1;
    daysInMonthRange.push(moment(requestObj.from).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'));
    while (dayCount < moment(requestObj.from).daysInMonth()) {
      daysInMonthRange.push(moment(requestObj.from).add(dayCount, 'days').startOf('day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'));
      dayCount++;
    }

    for (let index = 0, dataIndex = 0; index < daysInMonthRange.length; index++, dataIndex++) {
      if(dataIndex < data.length) {
        const entrySlot = moment.utc(data[dataIndex].arrival_ts).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
        if(!moment.utc(entrySlot).isSame(moment.utc(daysInMonthRange[index]))) {
          dataIndex--;
          emptyObject.arrival_ts = daysInMonthRange[index];
          modifiedData[index] = {...emptyObject};;
          emptyObject.arrival_ts = ''; 
        } else {
          modifiedData[index] = data[dataIndex];
        }
      } else {
        emptyObject.arrival_ts = daysInMonthRange[index];
        modifiedData[index] = {...emptyObject};
        emptyObject.arrival_ts = ''; 
      }
    }
  }
  else {//Jahr - moment.utc(date).month()
    const monthInYearRange = [];
    let monthCount = 1;
    monthInYearRange.push(moment(requestObj.from).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'));
    while (monthCount < 12) {
      monthInYearRange.push(moment(requestObj.from).add(monthCount, 'month').startOf('day').format('YYYY-MM-DD[T]HH:mm:ss[Z]'));
      monthCount++;
    }

    for (let index = 0, dataIndex = 0; index < monthInYearRange.length; index++, dataIndex++) {
      if(dataIndex < data.length) {
        const entrySlot = moment.utc(data[dataIndex].arrival_ts).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
        if(!moment.utc(entrySlot).isSame(moment.utc(monthInYearRange[index]))) {
          dataIndex--;
          emptyObject.arrival_ts = monthInYearRange[index];
          modifiedData[index] = {...emptyObject};;
          emptyObject.arrival_ts = ''; 
        } else {
          modifiedData[index] = data[dataIndex];
        }
      } else {
        emptyObject.arrival_ts = monthInYearRange[index];
        modifiedData[index] = {...emptyObject};
        emptyObject.arrival_ts = ''; 
      }
    }
  }
  return modifiedData;
}

const isExcludedDay = (entry) => {
  const excludedDays = ['Saturday', 'Sunday'];
  const day = moment.utc(entry.arrival_ts).format('dddd');
  return excludedDays.includes(day);
}

const addAverageDurations = (data) => {
  const nonZeroWaitDurationEntries = data.filter(entry => entry.wait_duration !== 0 && !isExcludedDay(entry));
  const nonZeroOffloadDurationEntries = data.filter(entry => entry.offload_duration !== 0 && !isExcludedDay(entry));
  const nonZeroTotalDurationEntries = data.filter(entry => entry.total_duration !== 0 && !isExcludedDay(entry));
  const modifiedData = {};
  modifiedData.entries = data;
  modifiedData.average_wait_duration = parseInt(nonZeroWaitDurationEntries.reduce((avgWaitDuration, entry) => avgWaitDuration + entry.wait_duration || 0, 0) / nonZeroWaitDurationEntries.length);
  modifiedData.average_offload_duration = parseInt(nonZeroOffloadDurationEntries.reduce((avgOffloadDuration, entry) => avgOffloadDuration + entry.offload_duration || 0, 0) / nonZeroOffloadDurationEntries.length);
  modifiedData.average_total_duration = parseInt(nonZeroTotalDurationEntries.reduce((avgTotalDuration, entry) => avgTotalDuration + entry.total_duration || 0, 0) / nonZeroTotalDurationEntries.length);
  return modifiedData;
}

export const processChartData = (actualChartData, requestObj, view) => {
  let withMissingDaysData = addMissingDays(actualChartData, requestObj, view);
  let withAverageDurationData = addAverageDurations(withMissingDaysData);
  const processedData = withAverageDurationData;
  return processedData;
}

export const withExcludedPredictions = (realData, predictionData) => {
 let modifiedData = {...predictionData};
  if(realData && realData.entries.length > 0 
      && predictionData && predictionData.entries.length > 0) {
    let predictionDataEntriesMap = {};
    predictionData.entries.map(entry => {
      predictionDataEntriesMap[entry.arrival_ts] = entry;
    });
    modifiedData.entries = [];
    modifiedData.entries = realData.entries.map(entry => {
      return predictionDataEntriesMap[entry.arrival_ts];
    });
  }
  return modifiedData;
}