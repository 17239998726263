import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../../src/react-dates-overrride.css';
import { SingleDatePicker } from 'react-dates';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import styles from './DatePicker.styles';
import classnames from "classnames";
import moment from 'moment';
import * as constants from '../../utils/constants';
import { isInclusivelyBeforeDay, isInclusivelyAfterDay } from '../../utils/datePickerUtils';

const useStyles = createUseStyles(styles);

const WeekPicker = ({ dateChangeHandler }) => {
  const classes = useStyles();
  const currentDate = moment();
  const [currentMonth, setCurrentMonth] = useState(moment().month());
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [focused, setFocused] = useState(false);
  const calculateActiveWeek = date => {
    const mon = date.clone().startOf("isoweek");
    const tue = mon.clone().add(1, "d");
    const wed = mon.clone().add(2, "d");
    const thu = mon.clone().add(3, "d");
    const fri = mon.clone().add(4, "d");
    const sat = mon.clone().add(5, "d");
    const sun = mon.clone().add(6, "d");
    return [sun, mon, tue, wed, thu, fri, sat];
  };
  const [selectedDays, setSelectedDays] = useState(calculateActiveWeek(currentDate));
  const [hoveredDays, setHoveredDays] = useState([]);
  const customFormat = '[ - ]DD.MM.YYYY';
  const [date, setDate] = useState(moment(currentDate.endOf('isoWeek').format('DD.MM.YYYY'),customFormat));
  const minDate = moment(currentDate.year() - constants.YEAR_COUNT, 'YYYY').startOf('month').subtract(1, 'days');
  const maxDate = moment().add(1, 'days');
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const dispatch = useDispatch();
 
  const onNavIconClick = (e) => {
    if (e.target.classList.value.includes('disabledArrow')) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  const PrevButton = () => (
    <div className={classnames(classes.prevButton, disablePrev ? [classes.disabledArrow, 'disabledArrow'] : '')} onClick={onNavIconClick}></div>  
  );
  
  const NextButton = () => (
    <div className={classnames(classes.nextButton, disableNext ? [classes.disabledArrow, 'disabledArrow'] : '')} onClick={onNavIconClick}></div> 
  );

  const ShortcutPanel = () => (
    <div className={classes.shortcutButtonPanel} onClick= {(e) => onWeekChange(currentDate, e)}>
      <span><a href='#' onClick={(e) => e.preventDefault()}>Aktuelle Woche</a></span>
    </div>
  );

  const isDayOutside = date => {
    let isOutsideMonth = false;
    selectedDays.forEach(selectedDay => {
      const isMonthMatch = selectedDay.month() === currentMonth;
      const isYearMatch = selectedDay.year() === currentYear;
      if (isMonthMatch && isYearMatch) {
        isOutsideMonth = true;
      }
    });
    return isOutsideMonth;
  };

  const isDayHighlighted = date => {
    let isHighlighted = false;
    selectedDays.forEach(selectedDay => {
      const isDayOfMonthMatch = selectedDay.date() === date.date();
      const isMonthMatch = selectedDay.month() === date.month();
      const isYearMatch = selectedDay.year() === date.year();
      if (isDayOfMonthMatch && isMonthMatch && isYearMatch) {
        isHighlighted = true;
      }
    });
    return isHighlighted;
  };

  const isDayHovered = date => {
    let isHovered = false;
    hoveredDays.forEach(hoveredDay => {
      const isDayOfMonthMatch = hoveredDay.date() === date.date();
      const isMonthMatch = hoveredDay.month() === date.month();
      const isYearMatch = hoveredDay.year() === date.year();
      if (isDayOfMonthMatch && isMonthMatch && isYearMatch) {
        isHovered = true;
      }
    });
    return isHovered;
  };

  const onWeekHoverIn = date => {
    setHoveredDays(calculateActiveWeek(date))
  };

  const onWeekHoverOut = () => {
    setHoveredDays([]);
  };

  const onWeekChange = (newDate, e) => {
    if (e.target.classList.value.includes('CalendarDay__blocked_out_of_range')) {
      e.stopPropagation();
      e.preventDefault();
    } else {
      setSelectedDays(calculateActiveWeek(newDate));
      setFocused(false);
      setDate(moment(newDate.endOf('isoWeek').format('DD.MM.YYYY'),customFormat));
      dateChangeHandler(moment(newDate).format('DD.MM.YYYY'));
      const isPrevDisabled = !(moment(newDate).subtract(1, 'month').isBetween(moment(minDate), moment(maxDate)));
      const isNextDisabled = !(moment(newDate).add(1, 'month').isBetween(moment(minDate), moment(maxDate)));
      setDisablePrev(isPrevDisabled);
      setDisableNext(isNextDisabled);
    }
  };

  const renderCalendarDay = (date) => {
    const currentWeekDays = calculateActiveWeek(currentDate);
    const currentWeekEndDate = moment(currentWeekDays[6]).add(2, 'days');
    let dayClasses = classnames(
      'CalendarDay',
      'CalendarDay__default',
      'CalendarDay_1',
      'CalendarDay__default_2'
    );
    let dayClassesOutsideRange = classnames(
      'CalendarDay',
      'CalendarDay_1',
      'CalendarDay__defaultCursor',
      'CalendarDay__defaultCursor_2',
      'CalendarDay__default',
      'CalendarDay__default_3',
      'CalendarDay__blocked_out_of_range',
      'CalendarDay__blocked_out_of_range_4'
    );
    let style = {
      width: '39px',
      height: '38px'
    };
    
    if (date.day) {
      // const isOutsideRange = isDayOutside(date.day);
      const isFutureDate = moment(date.day).isAfter(moment(currentWeekEndDate));
      const dayOfMonth = date.day.date();
      const isHovered = isDayHovered(date.day);
      const isHighlighted = isDayHighlighted(date.day);
      
      let style;
      if(isFutureDate) {
        style = {
          width: '39px',
          height: '38px',
          background: '#fff',
          color: '#cacccd'
        };
      } else {
        style = {
          width: '39px',
          height: '38px',
          background: isHighlighted ? 'linear-gradient(-360deg, #ffa522,#ffd006)' : (isHovered) ? '#bbefe5' : '#fff',
          color: isHighlighted ? 'white' : 'black'
        };
      }
      
      return (
        <td
          key={`day_${dayOfMonth}`}
          style={style}
          className={isFutureDate ? dayClassesOutsideRange : dayClasses}
          onClick={(e) => onWeekChange(date.day, e)}
          onMouseEnter={() => onWeekHoverIn(date.day)}
          onMouseLeave={() => onWeekHoverOut()}
        >
          {dayOfMonth}
        </td>
      );
    } else {
      return <td style={style} className={dayClasses} />;
    }
  };

  const onPrevMonthClick = (val) => {
    setCurrentMonth(val.month());
    setCurrentYear(val.year());
    const isPrevDisabled = !(moment(val).subtract(1, 'month').isBetween(moment(minDate), moment(maxDate)));
    setDisablePrev(isPrevDisabled);
    setDisableNext(false);
  }

  const onNextMonthClick = (val) => {
    setCurrentMonth(val.month());
    setCurrentMonth(val.year());
    const isNextDisabled = !(moment(val).add(1, 'month').isBetween(moment(minDate), moment(maxDate)));
    setDisablePrev(false);
    setDisableNext(isNextDisabled);
  }

  return (
    <div className='week-picker'>
    <span className={classnames(classes.weekNumber)}>{moment(date).startOf('isoWeek').format('DD')}</span>
    <SingleDatePicker
      id="week-picker" // PropTypes.string.isRequired,
      focused={focused} // PropTypes.bool
      date={date}
      onDateChange={() => {return null;}} // PropTypes.func.isRequired
      onFocusChange={({ focused }) => setFocused(focused)} // PropTypes.func.isRequired
      isDayBlocked={() => false}
      renderCalendarDay={renderCalendarDay}
      renderMonthText={month => constants.MONTH_MAP_DE_FORMAT_MMMM[moment(month, 'MMMM').month()+1] + ' ' +moment(month, 'MMMM').format('YYYY')}
      renderWeekHeaderElement={(day) => constants.DAY_MAP_DE_FORMAT_DD[day] ? constants.DAY_MAP_DE_FORMAT_DD[day] : day}
      displayFormat={customFormat}
      numberOfMonths={1}
      firstDayOfWeek={1}
      showDefaultInputIcon
      enableOutsideDays
      isOutsideRange = {day => isInclusivelyBeforeDay(day, minDate) || isInclusivelyAfterDay(day, maxDate)}
      hideKeyboardShortcutsPanel={true}
      inputIconPosition="after"
      navPrev={<PrevButton />}
      navNext={<NextButton />}
      renderCalendarInfo={() => (<ShortcutPanel />)}
      onPrevMonthClick={onPrevMonthClick}
      onNextMonthClick={onNextMonthClick}
    />
    </div>
  );
};

export default WeekPicker;