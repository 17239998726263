import logoExternal from './../../resources/images/logo_eew_external.png';
import logoInternal from './../../resources/images/logo_eew_digital.png';

export default ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '67px',
    background: 'white',
  },
  logoDigital: {
    display: 'flex',
    backgroundImage: `url(${logoInternal})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    height: '45px',
    width: '170px',
    marginLeft: '55px',
    marginTop: '13px',
  },
  logoExternal: {
    display: 'flex',
    backgroundImage: `url(${logoExternal})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    height: '45px',
    width: '170px',
    marginLeft: '55px',
    marginTop: '13px',
  },
  actions: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  logoutButton: {
    width: '125px',
    border: 'none',
    color: '#fff',
    background: 'linear-gradient(-360deg, #ffa522,#ffd006)',
    borderRadius: '2px',
    fontSize: '16px',
    height: '42px',
    marginRight: '55px',
    fontFamily: 'EEWMedium',
    cursor: 'pointer',
    '&:hover, &:focus': {
      outline: 'none',
    }
  },
  '@media screen and (max-width: 320px)': {
    headerContainer: {
      height: '64px',
      padding: 0,
    },
    logoExternal: {
      height: '45px',
      width: '170px',
      marginLeft: '24px',
      marginTop: '18px',
    },
  },
});