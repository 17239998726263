import initialState from '../initialState';
import * as actionTypes from '../actions/types';

const eewData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_LOADER: 
    case actionTypes.HIDE_LOADER: 
      return {...state, loader: action.loader };
    case actionTypes.SHOW_ERROR: 
    case actionTypes.HIDE_ERROR: 
      return {...state, error: action.error };
    case actionTypes.UPDATE_CHART_DATA: 
      return {...state, chartData: action.chartData };
    case actionTypes.UPDATE_PREDICTION_CHART_DATA: 
      return {...state, predictionChartData: action.predictionChartData };
    default:
      return state;
  }
}

export default eewData;
