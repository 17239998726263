
export const setCookie = (cname,cvalue,exdays) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = 'expires=' + d.toGMTString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}
  
export const getCookie = (cname) => {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
  
export const checkCookie = () => {
  var email=getCookie('eew-digital-email');
  var password=getCookie('eew-digital-password');
  if (email != '' && password != '') {
    return { email, password };
  } 
  else {
    if (email != '' && email != null) {
      setCookie('eew-digital-email', email, 30);
    }
    if (password != '' && password != null) {
      setCookie('eew-digital-password', password, 30);
    }
  }
  return null;
}

export const setSessionObjKey = (skey, svalue) => {
  let eewSessionObj = getSessionObj();
  if(eewSessionObj === null) {
    eewSessionObj = cresateSessionObj();
  }
  eewSessionObj[skey] = svalue; 
  sessionStorage.setItem('EEWDigitalSessionObj', JSON.stringify(eewSessionObj));
}

export const getSessionObjKey = (skey) => {
  const eewSessionObj = getSessionObj();
  return eewSessionObj ? eewSessionObj[skey] : null; 
}

const getSessionObj = () => {
  return JSON.parse(sessionStorage.getItem('EEWDigitalSessionObj'));
}

const cresateSessionObj = () => {
  sessionStorage.setItem('EEWDigitalSessionObj', JSON.stringify({}));
  return getSessionObj();
}

export const deleteSessionObj = () => {
  sessionStorage.removeItem('EEWDigitalSessionObj');
}