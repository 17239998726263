// import expandIcon from './../../resources/icons/icons8-expand-arrow-24.png';
// import collapseIcon from './../../resources/icons/icons8-collapse-arrow-24.png';

import { stackOffsetNone } from "d3";

export default ({
  dashboardContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#f7f8f8',
    // height: '957px',
    paddingTop: '24.6px',
    paddingBottom: '13px',
  },
  dashboard: {
    margin: '0 auto',
  },
  externalDashboard: {
    display: 'flex',
    margin: '0',
  },
  eewExternal: {
    paddingTop: '47px',
  },
  filterActions: {
    width: '1330px',
    display: 'flex',
    height: '41px',
    marginBottom: '23.4px',
    '& label': {
      fontSize: '14px',
      fontFamily: 'EEWMedium',
      '& i': {
        marginLeft: '20px',
        marginRight: '2px',
        display: 'inline-block',
        verticalAlign: 'middle',
        width:  '10px',
        height: '10px',
        borderRadius: '50%',
        transition: '0.2s',
        boxShadow: 'inset 0 0 0 8px #fff',
        border: '1px solid #e5e6e6',
        background: '#fff',
        cursor: 'pointer',
      },
      '&:hover &i': {
        boxShadow: 'inset 0 0 0 2px #fff',
        background: '#ffa522',
      }
    },
    '& input[type = "radio"]': {
      position: 'absolute',
      opacity: 0,
      width: 0,
      height: 0,
    },
    '& input[type = "radio"]:checked': {
      '& i': {
        boxShadow: 'inset 0 0 0 8px #ffa522',
        background: '#ffa522',
      }
    },
    '& input[type = "date"]': {
      height: '2.5rem',
      width: '9rem',
      paddingLeft: '10px',
    },
    '& select': {
      height: '2.5rem',
      width: '7rem',
      padding: '5px',
      marginLeft: '1rem',
      marginRight: '1rem',
      borderRadius: '0.3rem',
      borderColor: '#e5e6e6',
      // background: `url(${expandIcon}) no-repeat`,
      appearance: 'none',
      backgroundPosition: '5.2rem 0.5rem',
    }
  },
  leftActions: {
    
  },
  rightActions: {
    display: 'flex',
    flex: 'auto',
    justifyContent: 'flex-end',
    '& label': {
      lineHeight: '41px',
    },
  },
  chartContainer: {
    width: '1330px',
    display: 'flex',
    backgroundColor: '#f7f8f8',
    height: '620px',
    minHeight: '550px',
    marginBottom: '22px',
    flex: '1 auto',
  },
  impressumBorder: {
    borderBottom: 'solid 1px #f29205',
    paddingBottom: '1px',
    maxWidth: 'fit-content',
    margin: '0 56px',
    ':focus': {
      outline: 'none',
    }
  },
  imprintExternalBorder: {
    margin: '0 40px',
  },
  impressum: {
    color: '#ff9e28',
    fontSize: '0.8rem',
    textDecoration: 'none',
    fontFamily: 'EEWMedium',
    '&:focus, &:active': {
      outline: 'none',
    }
  },
  weekNavigationIcon: {
    position: 'relative',
    width: '40px',
    height: '40px',
    top: '250px',
    flex: 'auto',
  },
  prevButton: {
    color: '#f29205',
    content: "",
    position: 'relative',
    fontSize: '3rem',
    borderRadius: '2px',
    height: '55px',
    width: '40px',
    top: '312px',
    cursor: 'pointer',
    '&focus': {
      outline: 'none',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '15px',
      top: '20px',
      border: 'solid #606060',
      borderWidth: '0px 3px 3px 0px',
      borderRadius: '1px',
      display: 'inline-block',
      padding: '7px',
      transform: 'rotate(135deg)',
    },
    '&:hover:after': {
      borderColor: '#ffa522',
      transform: 'scale(1.2) rotate(135deg)',
      transitionDuration: '0.2s',
    },
  },
  nextButton: {
    color: '#f29205',
    content: "",
    position: 'relative',
    fontSize: '3rem',
    borderRadius: '2px',
    height: '55px',
    width: '40px',
    top: '312px',
    cursor: 'pointer',
    '&focus': {
      outline: 'none',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      right: '15px',
      top: '20px',
      border: 'solid #606060',
      borderWidth: '3px 0px 0px 3px',
      borderRadius: '1px',
      display: 'inline-block',
      padding: '7px',
      transform: 'rotate(135deg)',
    },
    '&:hover:after': {
      borderColor: '#ffa522',
      transform: 'scale(1.2) rotate(135deg)',
      transitionDuration: '0.2s',
    },
  },
  disabledArrow: {
    '&:after': {
      display:'none',
      cursor: 'not-allowed',
      borderColor: '#d3d3d3',
    },
  },
  '@media screen and (max-width: 320px)': {
    eewExternal: {
      paddingTop: '0',
    },
    dashboard: {
      margin: '0',
      display: 'inline-flex',
      backgroundColor: '#fff',
    },
    dashboardContainer: {
    //  height: '89vh',
    },
    chartContainer: {
      padding: '0rem',
      height: 'auto',
      minHeight: '462px',
      width: '320px',
      marginBottom: '12px',
    },
    impressumBorder: {
      alignSelf: 'flex-start',
      margin: '0px 0px 0px 30px',
    },
    weekNavigationIcon: {
      width: '20px',
      top: '225px',
      height: '0px',
    },
    prevButton: {
      top: '240px',
      height: '55px',
      width: '35px',
      padding: 0,
      '&:after': {
        left: '15px',
        padding: '4px',
        borderWidth: '0px 2px 2px 0px',
      },
      '&:hover:after': {
        borderColor: 'solid #ffa522',
        transform: 'scale(1.2) rotate(135deg)',
        transitionDuration: '0.2s',
      },
    },
    nextButton: {
      padding: 0,
      top: '240px',
      height: '55px',
      width: '35px',
      '&:after': {
        right: '15px',
        padding: '4px',
        borderWidth: '2px 0px 0px 2px',
      },
      '&:hover:after': {
        borderColor: 'solid #ffa522',
        transform: 'scale(1.2) rotate(135deg)',
        transitionDuration: '0.2s',
      },
    },
  },
});
