import rightArrow from './../../resources/icons/arrow-orange.png';

export default ({
  imprintContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingLeft: '55px',
    paddingRight: '300px',
    background: '#fff',
    '& h3, & p': {
      margin: '0',
      padding: '0',
      border: '0',
      outline: '0',
      verticalAlign: 'baseline',
      background: 'transparent',
    },
    '& h3': {
      fontSize: '16px',
      lineHeight: '20px',
      marginBottom: '13px',
      marginTop: '8px',
      fontFamily: 'EEWBold, arial, sansserif',
      fontWeight: '400',
      color: '#8e1c18',
    },
    '& p': {
      marginBottom: '26px',
      fontFamily: 'EEWLight, arial, sans-serif',
      fontSize: '14px',
    }
  },
  morelink: {
    color: '#000',
    textDecoration: 'none',
    backgroundImage: `url(${rightArrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
    paddingRight: '16px',
  },
  heading: {
    color: '#ff9e28',
    fontSize: '16px',
    fontFamily: 'EEWBold',
    width: '78px',
    height: '17px',
    marginTop: '53px',
    marginBottom: '33px',
  },
  backLinkBorder: {
    borderBottom: 'solid 1px #f29205',
    paddingBottom: '1px',
    maxWidth: 'fit-content',
    marginTop: '26px',
    marginBottom: '99px',
    ':focus': {
      outline: 'none',
    }
  },
  backLink: {
    color: '#ff9e28',
    fontSize: '12px',
    textDecoration: 'none',
    fontFamily: 'EEWMedium',
    '&:focus, &:active': {
      outline: 'none',
    }
  },
  '@media screen and (max-width: 320px)': {
    imprintContainer: {
      paddingLeft: '24px',
      paddingRight: '30px',
      '& h3': {
        fontSize: '12px',
        lineHeight: '14px',
        marginTop: '0px',
        marginBottom: '10px',
      },
      '& p': {
        marginBottom: '25px',
        fontSize: '12px',
      }
    },
    heading: {
      marginTop: '26px',
      marginBottom: '25px',
    },
    backLinkBorder: {
      marginTop: '17px',
      marginBottom: '17px',
    },
  },
});
