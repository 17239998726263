import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import styles from './ChartUI.styles';
// import { yearData } from '../../resources/mockData';
import moment from 'moment';
import * as constants from '../../utils/constants';
import { getChartData } from '../../redux/selectors';

const useStyles = createUseStyles(styles);

const YearChart = ({ selectedYear, selectedFilterType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const yearData = useSelector(getChartData);
  const chartContainer = useRef(null);
  const [ year, setYear ] = useState(selectedYear);
  const [ filterType, setFilterType ] = useState(selectedFilterType);
  const monthMapDe = constants.MONTH_MAP_DE_FORMAT_MMMM;
  const currentDate = moment().startOf('month').format('DD.MM.YYYY');

  const getBarClass = (entry) => {
    if(moment(entry.arrival_ts).format('DD.MM.YYYY') === (moment(currentDate, 'DD.MM.YYYY').format('DD.MM.YYYY'))) {
      return 'selectedBar';
    }
    return '';
  }
  
  const getMonth = (date) => {
    return monthMapDe[moment.utc(date).month()+1];
  }
  const getAverageDuration = () => {
    if (selectedFilterType === 'Wartezeit') {
      return yearData.average_wait_duration;
    } else if (selectedFilterType === 'Entleerung') {
      return yearData.average_offload_duration;
    } else {//Gesamtdauer
      return yearData.average_total_duration;
    }
  }

  const getDuration = (d) => {
    if (selectedFilterType === 'Wartezeit') {
      return d.wait_duration;
    } else if (selectedFilterType === 'Entleerung') {
      return d.offload_duration;
    } else {//Gesamtdauer
      return d.total_duration;
    }
  }     

  useEffect(() => {
    if (!(year == selectedYear && selectedFilterType === filterType)) {
      d3.selectAll("svg > g, line, text").remove();
      setYear(selectedYear);
      setFilterType(selectedFilterType);
  }

  if (yearData.entries.length > 0 && chartContainer.current) {
    const svg = d3.select('.svgChart');
    const margin = 0,
          width = 1226 - margin,
          height = 400 - margin;

      const xScale = d3.scaleBand()
                    .domain(yearData.entries.map(function(d) { return getMonth(d.arrival_ts); }))
                    .range([0, width])
                    .paddingInner(0.1)
                    .paddingOuter(0);
      const yScale = d3.scaleLinear()
                    .domain([0, d3.max(yearData.entries, function(d) { return getDuration(d); })+20])
                    .range([height, 20]);

      const g = svg.append('g').attr('transform', 'translate(' + 40 + ',-' + 10 + ')');

      g.append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .attr('class', 'x axis')
        .call(d3.axisBottom(xScale))
        // .append('text')
        // .attr('class', 'x-axis-indicator')
        // .attr('y', 15)
        // .attr('x', width + 45)
        // .attr('text-anchor', 'end')
        // .attr('stroke', 'black')
        // .text('Uhrzeit');

      const g1 = g.append('g')
      g1.attr('class', 'y axis')
        .call(d3.axisLeft().scale(yScale).ticks(10).tickSizeInner(-width).tickPadding(10))
        .append('text')
        .attr('class', 'y-axis-indicator')
        .attr('x', 22)
        .attr('y', 6)
        .attr('dy', '-1em')
        .attr('text-anchor', 'end')
        .attr('stroke', 'black')
        .text('In Minuten');
      g1.select(".tick text")
        .text('');

      if (getAverageDuration() > 0) {
        svg.append('svg:line')
          .attr('class', 'average-duration-indicator')
          .attr('x1', 40)
          .attr('x2', width+40)
          .attr('y1', yScale(getAverageDuration())-10)
          .attr('y2', yScale(getAverageDuration())-10)
          .attr('class', 'averageLine');
        
        svg.append('text')
          .attr('class', 'average-duration-indicator-text')
          .attr('x', width+40)
          .attr('y', yScale(getAverageDuration())-10)
          .attr('dy', '-0.5em')
          .attr('text-anchor', 'end')
          .attr('stroke', 'black')
          .text('DURCHSCHNITTLICHE AUFENTHALTSDAUER');
      }
      g.selectAll('.bar')
      .data(yearData.entries)
      .enter().append('rect')
      .attr('class', function(entry) {
        return 'bar ' + getBarClass(entry);
      })
      .attr('x', function(d) { return xScale(getMonth(d.arrival_ts)); })
      .attr('y', function(d) { return yScale(getDuration(d)); })
      .attr('width', xScale.bandwidth())
      .attr('height', function(d) { return height - yScale(getDuration(d)); })
      .on('mouseover', function(entry) {
        tooltip.style('visibility', 'visible');
        tooltip.select('div')
              .html('<span style="font-family: EEWMedium; font-size: 20px">ø</span> ' + getDuration(entry) + ' MIN')
              .style('position', 'fixed')
              .style('left', (d3.event.pageX) + 'px')
              .style('top', (d3.event.pageY) + 'px');
        this.classList.add('activeBar');
      })
      .on('mouseleave', function(entry) {
        tooltip.style('visibility', 'hidden');
        this.classList.remove('activeBar');
      });
      
      const tooltip = d3.select('body').append('div')
        .attr('class', 'tooltip')
        .style('visibility', 'hidden');
      
      tooltip.append('rect')
        .attr('width', 30)
        .attr('height', 20)
        .attr('fill', '#fffff');

      tooltip.append('div')
        .attr('x', 15)
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .attr('font-size', '1em')
        .attr('font-weight', 'bold');
    }
  },[yearData, selectedYear, selectedFilterType, chartContainer.current])

  return (<section className={classnames(classes.trackingChart)}>
            <div className={classnames(classes.chartHeading)}>
              <label className={classnames(classes.heading)}>AUFENTHALTSDAUER IM JAHR</label>
              <br />
              <label className={classnames(classes.date)}>Anlage Helmstedt, {year}</label>
            </div>
            <svg className='svgChart' ref={chartContainer}>
              <defs>
                <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" style={{stopColor:'#ffd006',stopOpacity:'1'}} />
                  <stop offset="100%" style={{stopColor:'#ffa522',stopOpacity:'1'}} />
                </linearGradient>
              </defs>
            </svg>
            <div className={classnames(classes.legendsContainer)}>
              <div className={classnames(classes.colorBoxNormal)}></div><label>Geöffnet</label>
              <div className={classnames(classes.colorBoxCurrent)}></div><label>Jetzt</label>
              <div className={classnames(classes.colorBoxHighlighted)}></div><label>Auswahl</label>
            </div>
          </section>);
}

export default YearChart;

